import React, { FC } from 'react'
import { PopulationInfoContainer } from '../info.component'
import { ArrowRight, PopulationIcon } from '../../../assets/icons'
import { Text } from '../../text.component'
import { formatDate, getLang } from '../../../utils/helpers.utils'
import { useTranslation } from 'react-i18next'

interface IProps {
  info: any
  handleRegionClick: (kato: string) => void
}

const PopulationTab: FC<IProps> = ({ info, handleRegionClick }) => {
  const { t } = useTranslation();
  return (
    <PopulationInfoContainer>
      <div className="population-item total">
        <div className="population-item__icon">
          <PopulationIcon />
        </div>
        <div className="flex-1">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem' >{info[`name${getLang()}`] || 'Выбранный район'}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.875rem' >{formatDate(info.date).replaceAll('-', '.') || t('no-data')}</Text>
        </div>
        <div className='ttl'>
          <Text color='var(--primary-color)' fontWeight={700} fontSize='0.875rem'>{info.population?.toLocaleString() || 0}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{t('form.people')}</Text>
        </div>
        <div className="population-item__icon">
        </div>
      </div>

      {
        info.childInfoList.map((village: any) => (
          <div className="population-item">
            <div className="population-item__icon">
              <PopulationIcon />
            </div>
            <div className="flex-1">
              <Text color='var(--text-color)' fontWeight={400} fontSize='0.875rem'>{village[`name${getLang()}`]}</Text>
            </div>
            <div>
              <Text color='var(--text-color)' fontWeight={400} fontSize='0.875rem'>{village.population?.toLocaleString() || 0}</Text>
            </div>
            <div className="population-item__icon arrow" onClick={() => handleRegionClick(village.kato)}>
              <ArrowRight width={5} />
            </div>
          </div>
        ))
      }
    </PopulationInfoContainer>
  )
}

export default PopulationTab