import React, { FC } from 'react'
import { PopulationInfoContainer } from '../info.component'
import { AreaIcon, SalaryIcon } from '../../../assets/icons'
import { Text } from '../../text.component'
import { useTranslation } from 'react-i18next'
import { getLang } from '../../../utils/helpers.utils'

const AreaTab: FC<{ info: any }> = ({ info }) => {
  const { t } = useTranslation();
  return (
    <PopulationInfoContainer>
      <div className="population-item total">
        <div className="population-item__icon">
          <AreaIcon />
        </div>
        <div className="flex-1">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('aktobe.area')}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.875rem'>{info[`name${getLang()}`] || 'Выбранный район'}</Text>
        </div>
        <div className='ttl'>
          <Text color='var(--primary-color)' fontWeight={700} fontSize='0.875rem'>{info.area?.toLocaleString() || 0}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>га</Text>
        </div>
      </div>
      <div className="population-item total">
        <div className="population-item__icon">
          <SalaryIcon />
        </div>
        <div className="flex-1">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('aktobe.avgSalary').split(' ')[0]}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.875rem'>{t('aktobe.avgSalary').split(' ').slice(1).join(' ')}</Text>
        </div>
        <div className='ttl'>
          <Text color='var(--primary-color)' fontWeight={700} fontSize='0.875rem'>{info.avgSalary?.toLocaleString() || 0}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>тенге</Text>
        </div>
      </div>

    </PopulationInfoContainer>
  )
}

export default AreaTab