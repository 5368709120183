import React, { useEffect } from 'react';
import { headerOptions, navLinks } from '../../constants/navlinks.constant';
import { useTranslation } from 'react-i18next';

import { NavBarWrapper, NavItem, NavIcon } from './index.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowRight, NavbarIcon } from '../../assets/icons';
import { Text } from '../text.component';
import { OBLAST_KATO } from '../../utils/helpers.utils';

interface IProps {
  selectedTab: string | null;
  setSelectedTab: (link: string) => void;
}

const NavBar: React.FC<IProps> = ({ selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { kato, tab } = useParams();

  const [options, setOptions] = React.useState<string[]>(headerOptions);

  const handleSelectLink = (link: string) => {
    setSelectedTab(link);
    link !== 'infrastructure-projects'
      ? navigate(`/${kato}/${link}`)
      : tab !== 'undefined' || tab !== undefined
        ? navigate(`/${kato}/${link}/${tab}`)
        : navigate(`/${kato}/${link}/education`)
  }

  useEffect(() => {
    if (kato) {
      if (+kato === OBLAST_KATO){
        setOptions(headerOptions);
      } else {
        setOptions(headerOptions.filter((option) => option !== 'finance-aid'));
      } 
    }
  }, [kato])


  return (
    <NavBarWrapper>
      {options.map((link) =>
        <NavItem
          key={link}
          onClick={() => handleSelectLink(link)}
          isSelected={selectedTab === link}>
          <NavIcon>
            <NavbarIcon />
          </NavIcon>
          <Text className='flex-1' fontSize='0.875rem' color={selectedTab === link ? 'white' : 'var(--text-color)'} fontWeight={400}>{t(link)}</Text>
          <NavIcon>
            <ArrowRight width={5} />
          </NavIcon>
        </NavItem>)
      }
    </NavBarWrapper>
  )
}

export default NavBar