import React, { FC, useState } from 'react'
import styled from 'styled-components';
import { ScreeningContainer } from '../tabs/index.styles';
import { useTranslation } from 'react-i18next';
import AllPsdTab from '../psd-tabs/all-psd-tab.component';
import TopTenPsd from '../psd-tabs/top-ten-psd.component';

const PSDContainer = styled(ScreeningContainer)`
  .psd-body {
    background: #fff;
    height: 100%;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.625rem;
    > div {
      max-width: 99%; 
    }
  }

  .psd-chart-container {
    height: 380px;
  }
`;

const tabs = ['all', 'top', 'tables']

const PSDFullScreen: FC<{ kato: string }> = ({ kato }) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);

  return (
    <PSDContainer>
      <div className="tabs">
        {tabs.map((tab: string) => (
          <div
            key={tab}
            className={selectedTab === tab ? 'tab active' : 'tab'}
            onClick={() => setSelectedTab(tab)}
          >
            {t('psd-page.' + tab)}
          </div>
        ))}
      </div>

      <div className="psd-body">
        {selectedTab === tabs[0] && <AllPsdTab />}
        {selectedTab === tabs[1] && <TopTenPsd />}
      </div>

    </PSDContainer>
  )
}

export default PSDFullScreen