import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

import { getProjectList } from '../../requests/snp.request';
import { EducationContainer, EducationItem } from './index.styles';
import 'react-toastify/dist/ReactToastify.css';
import ZoomableImageModal from './zoomable-image.component';
import AdditionalFields from './additional-fields.component';
import { checkVal } from '../../utils/helpers.utils';
import StreetList from './street-list.component';
import { Text } from '../text.component';

const Lighting = ({ kato, contentRef }: { kato: number, contentRef: any }) => {
  const { t } = useTranslation();

  const [projectList, setProjectList] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImageList, setCurrentImageList] = useState<any[]>([]);
  const [objectName, setObjectName] = useState<string>('Фото');

  const openImageModal = (image: any, images: any, name: string) => {
    setIsOpen(true);
    setSelectedImage(image);
    const imageIdList = images.map((image: any) => image.id);
    setCurrentImageList(imageIdList);
    setObjectName(name);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
    setCurrentImageList([]);
  }

  useEffect(() => {
    const snpData = JSON.parse(localStorage.getItem('snp') as string);
    const val = kato ? kato : snpData.kato
    if (val) {
      getProjectList('lighting', val)
        .then((res) => {
          const updatedList = res.map((data: any) => ({
            ...data,
            streetList: data.streetList.map((street: any) => {
              const fileGroups = street.files.reduce((groups: any, file: any) => {
                if (!groups[file.fileType]) {
                  groups[file.fileType] = [];
                }
                groups[file.fileType].push(file);
                return groups;
              }, {});

              const images = Object.entries(fileGroups).reduce((result: any, [fileType, files]) => {
                result[fileType] = files;
                return result;
              }, {});

              return {
                ...street,
                images
              };
            })
          }));

          setProjectList(updatedList);
        })
        .catch((err) => {
          toast.error('Снп не выбрано');
        })
    }
  }, [])

  useEffect(() => {
    getProjectList('lighting', kato)
      .then((res) => {
        const updatedList = res.map((data: any) => ({
          ...data,
          streetList: data.streetList.map((street: any) => {
            const fileGroups = street.files.reduce((groups: any, file: any) => {
              if (!groups[file.fileType]) {
                groups[file.fileType] = [];
              }
              groups[file.fileType].push(file);
              return groups;
            }, {});

            const images = Object.entries(fileGroups).reduce((result: any, [fileType, files]) => {
              result[fileType] = files;
              return result;
            }, {});

            return {
              ...street,
              images
            };
          })
        }));

        setProjectList(updatedList);
      })
      .catch((err) => {
        toast.error('Снп не выбрано');
      })
  }, [kato])

  if (projectList.length === 0) {
    return <p>{t('no-objects')}</p>
  }

  return (
    <>
      <EducationContainer ref={contentRef}>
        {projectList.length > 0 && projectList.map((project) =>
          <EducationItem key={project.id}>
            <div className="body" style={{ paddingTop: '0.625rem' }}>
              {
                ['totalStreetNumber', 'totalStreetLength', 'lightenedStreetNumber', 'lightenedStreetLength', 'lighteningCover'].some((key) => checkVal(project[key]))
                && <div className='items'>
                  {
                    checkVal(project.totalStreetNumber)
                    && <div className='item'>
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t('form.totalStreetNumber').split(',')[0]}:</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project.totalStreetNumber} </Text>
                    </div>
                  }
                  {
                    checkVal(project.totalStreetLength)
                    && <div className='item'>
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t('form.totalStreetLength').split(',')[0]}:</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project.totalStreetLength} км</Text>
                    </div>
                  }
                  {
                    checkVal(project.illuminated)
                    && <div className='item'>
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t('form.illuminated').split(',')[0]}:</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project.illuminated}</Text>
                    </div>
                  }
                  {
                    checkVal(project.illuminatedStreetLength)
                    && <div className='item'>
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t('form.illuminatedStreetLength').split(',')[0]}:</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project.illuminatedStreetLength}</Text>
                    </div>
                  }
                  {
                    (project.illuminatedStreetLength !== null && project.totalStreetLength !== null && project.totalStreetLength !== 0) &&
                    <div className='item'>
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t('form.lightingCover').split(',')[0]}:</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{Math.round(+(+project.illuminatedStreetLength * 100 / +project.totalStreetLength))}%</Text>
                    </div>
                  }
                </div>
              }

              {checkVal(project.additionalFields) && Object.keys(project.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key)).length > 0 && <AdditionalFields project={project} />}

              {project.streetList?.length > 0 && <StreetList streetList={project.streetList} openImageModal={openImageModal} />}
            </div>
          </EducationItem>
        )}
        <ToastContainer />
        {selectedImage && (
          <ZoomableImageModal
            isFileUpload={true}
            isOpen={isOpen}
            onClose={closeImageModal}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            currentImageList={currentImageList}
            objectName={objectName}
          />
        )}
      </EducationContainer >
    </>
  )
}

export default Lighting