import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { IPerson } from '../../interfaces/snp.interface';
import PhotoPlaceholder from '../../assets/images/photo-placeholder.png';
import { PassportContainer, Photo, PersonInfo } from './index.styles'
import ZoomableImageModal from '../right-menu/zoomable-image.component';
import { capitalize, formatDate, getLang, keepCapitalized } from '../../utils/helpers.utils';
import { Phone } from '../../assets/icons';
import { getImageUrl } from '../admin-page/general-info.component';
import { Text } from '../text.component';

interface IProps {
  person: IPerson,
}

const Passport: React.FC<IProps> = memo(({ person }) => {
  const { t } = useTranslation()

  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openImageModal = (imageId: number) => {
    setIsOpen(true);
    setSelectedImage(imageId);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  }

  return (
    <PassportContainer>
      <Photo
        src={(person.imageId && getImageUrl(+person.imageId)) || PhotoPlaceholder}
        alt='profile photo' onClick={() => person.imageId && openImageModal(+person.imageId)}
      />
      <PersonInfo>
        <div>
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{capitalize(person[`name${getLang()}`] as string)?.split(' ')[0]}</Text>
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{capitalize(person[`name${getLang()}`] as string)?.split(' ')?.slice(1)?.join(' ')}</Text>
        </div>

        <Text color='#717579' fontWeight={400} fontSize='0.875rem'>{keepCapitalized(person[`position${getLang()}`] as string)}</Text>

        <div className="group">
          <div className="item">
            <Text color='#717579' fontWeight={400} fontSize='0.875rem'>{t('people.worksFrom')}</Text>
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{person.inPositionFrom && formatDate(person.inPositionFrom)}</Text>
          </div>

          <div className="item">
            <Phone />
            <a className='phone-number' href={`tel:${person.phone}`}>{person.phone}</a>
          </div>

        </div>
      </PersonInfo>

      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={[]}
        />
      )}

    </PassportContainer>
  )
});

export default Passport