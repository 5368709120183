import React, { FC, useContext, useEffect, useState } from 'react'
import { BasicStatsItem } from '../index.styles';
import { OBLAST_KATO } from '../../../utils/helpers.utils';

interface IProps {
  kato: string;
}

const aktobeLink = 'https://pbi.csi.kz/Report1/powerbi/Aktobe/Aktobe_%D0%B4%D0%BE%D1%85%D0%BE%D0%B4%D1%8B_%D1%80%D0%B0%D0%B9%D0%BE%D0%BD%D0%B0_%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C?rs:embed=true';
const irgizLink = 'https://pbi.csi.kz/Report1/powerbi/Aktobe/Aktobe_%D0%B4%D0%BE%D1%85%D0%BE%D0%B4%D1%8B_%D1%80%D0%B0%D0%B9%D0%BE%D0%BD%D0%B0?rs:embed=true'

const DistrictIncomeFullScreen: FC<IProps> = ({ kato }) => {
  const [link, setLink] = useState<string>(aktobeLink)

  useEffect(() => {
    if (+kato === OBLAST_KATO) {
      setLink(aktobeLink)
    } else {
      setLink(irgizLink)
    }
  }, [kato])

  return (
    <BasicStatsItem
      style={{
        width: '100%',
        height: '100%',
        flex: '1',
      }}>
      <iframe title="DI iframe" src={link} />
    </BasicStatsItem>
  )
}

export default DistrictIncomeFullScreen