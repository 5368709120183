import React from 'react';
import { IProject } from '../../interfaces/snp.interface';
import { getLang, t } from '../../utils/helpers.utils';
import { useTranslation } from 'react-i18next';
import { Text } from '../text.component';

interface IProps {
  project: IProject;
}

const AdditionalFields: React.FC<IProps> = ({ project }) => {
  const { i18n: { language } } = useTranslation();

  return (
    <>
      {project.additionalFields && Object.keys(project.additionalFields).length > 0 && (
        <div className="block">
          <Text color="#118F84" fontSize="0.875rem" fontWeight={400}>
            {t('form.additionalFields', language)}
          </Text>
          <div className="items">
            {Object.keys(project.additionalFields)
              .filter((key: string) => !['ru', 'kz'].includes(key))
              .map((section) => {
                const field = project.additionalFields[section];
                const label = field[`label${getLang()}`]?.trim();
                const value = field[`value${getLang()}`]?.trim();
                const link = field.link;
                const linkLabel = field[`linkLabel${getLang()}`]?.trim();

                return (
                  <div className="item" key={section}>
                    {section.startsWith('link') ? (
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}>
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#118F84', textDecoration: 'underline', fontSize: '0.875rem' }}
                        >
                          {linkLabel}
                        </a>
                      </Text>
                    ) : !section.includes('no-label') ? (
                      <>
                        <Text color="#818689" fontSize="0.875rem" fontWeight={400}>
                          {`${label}: `}
                        </Text>
                        <Text color="##202224" fontSize="0.875rem" fontWeight={500}>
                          {value}
                        </Text>
                      </>
                    ) : (
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}>
                        {field[`text${getLang()}`]}
                      </Text>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default AdditionalFields;
