import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from '../../text.component';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BasicStatsItem } from '../index.styles';
import { topTenPSDOptions } from './chart-options';
import { IPSDFilter, IPSDFilterList } from '../../../interfaces/snp.interface';
import { useParams } from 'react-router-dom';
import { getPsdFilters, getTopPsdData } from '../../../requests/kato.request';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'
import { IChart } from './all-psd-tab.component';

const TopTenPsdContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.25rem;
  gap: 0.625rem;

  > div {
    max-width: 99% 
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

const FilterItem = styled.div`
  display: flex;
  width: 100%;
  gap: 0.625rem;
  flex-direction: column;
  max-width: 24rem;

  select {
    border: 1px solid #DBDCE0;
    border-radius: 5px;
    height: 2rem;
    text-indent: 0.625rem;
    font-size: 0.75rem;
    max-width: 24rem;
    &:focus {
      outline: none;
    }

    option {
      max-width: 24rem;
    }
  }

  .dates {
    display: flex;
    gap: 0.3125rem;
    align-items: center;
    max-width: 24rem;

    input {
      border: 1px solid #DBDCE0;
      border-radius: 5px;
      height: 2rem;
      text-indent: 0.625rem;
      flex: 1;
      font-size: 0.75rem;

      &:focus {
        outline: none;
      }
    }
  }
`;

const keyToListKey = {
  projectName: 'projectNames',
  customer: 'customers',
  objectLocation: 'objectLocations',
  examCompany: 'examLocations',
  bin: 'bins',
  financingSource: 'financingSources',
  designer: 'designers',
  conclusionNumber: 'conclusionNumbers',
} as Record<keyof IPSDFilter, keyof IPSDFilterList>;

interface IPSDFilterOptions {
  bins: { label: string, value: string }[],
  conclusionNumbers: { label: string, value: string }[],
  customers: { label: string, value: string }[],
  designers: { label: string, value: string }[],
  financingSources: { label: string, value: string }[],
  objectLocations: { label: string, value: string }[],
  projectNames: { label: string, value: string }[],
  examLocations: { label: string, value: string }[]
  minDate?: string;
  maxDate?: string;
}

const getText = (str: string) => {
  return <Text fontWeight={400} color="#202224" fontSize='0.75rem'>{str}</Text>
}

const filterNames: (keyof IPSDFilter)[] = ['projectName', 'customer', 'objectLocation', 'examCompany', 'bin', 'financingSource', 'designer', 'conclusionNumber']

const TopTenPsd = () => {
  const { t } = useTranslation();
  const { kato } = useParams();
  const [client, setClient] = useState<IChart>();
  const [category, setCategory] = useState<IChart>();

  const [filterDict, setFilterDict] = useState<IPSDFilterOptions>({
    bins: [],
    conclusionNumbers: [],
    customers: [],
    designers: [],
    financingSources: [],
    objectLocations: [],
    projectNames: [],
    examLocations: [],
    minDate: "",
    maxDate: ""
  });

  const [filters, setFilters] = useState<IPSDFilter>({
    projectName: '',
    customer: '',
    objectLocation: '',
    examCompany: '',
    bin: '',
    financingSource: '',
    designer: '',
    conclusionNumber: '',
    startDate: '',
    endDate: ''
  })

  useEffect(() => {
    if (kato) {
      getTopPsdData(+kato, filters).then((res) => {
        const client = res.client.sort((a, b) => b.count - a.count).splice(0, 10);
        const category = res.category.sort((a, b) => b.count - a.count).splice(0, 10);

        const clientOptions = {
          ...topTenPSDOptions,
          scales: {
            ...topTenPSDOptions.scales,
            x: {
              ...topTenPSDOptions.scales.x,
              max: Math.max(...client.map(p => p.count)) * 1.25,
              beginAtZero: true,
            }
          }
        }

        const categoryOptions = {
          ...topTenPSDOptions,
          scales: {
            ...topTenPSDOptions.scales,
            x: {
              ...topTenPSDOptions.scales.x,
              max: Math.max(...category.map(p => p.count)) * 1.25,
              beginAtZero: true,
            }
          }
        }

        setClient({
          data: {
            labels: client.map(p => p.client),
            datasets: [
              {
                label: 'Количество ПСД',
                data: client.map(p => p.count),
                backgroundColor: "#118F84",
                barThickness: 9,
                borderRadius: 2,
              }
            ]
          },
          options: clientOptions,
        })

        setCategory({
          data: {
            labels: category.map(p => p.category),
            datasets: [
              {
                label: 'Количество ПСД',
                data: category.map(p => p.count),
                backgroundColor: "#118F84",
                barThickness: 9,
                borderRadius: 2,
              }
            ]
          },
          options: categoryOptions,
        })

        getPsdFilters(+kato, filters).then((res) => {
          setFilterDict({
            bins: ['', ...res.bins.filter(item => !!item.length)].map((option: string) => ({ value: option, label: t(option) || 'Все' })),
            conclusionNumbers: ['', ...res.conclusionNumbers].map((option: string) => ({ value: option, label: t(option) || 'Все' })),
            customers: ['', ...res.customers].map((option: string) => ({ value: option, label: t(option) || 'Все' })),
            designers: ['', ...res.designers].map((option: string) => ({ value: option, label: t(option) || 'Все' })),
            financingSources: ['', ...res.financingSources].map((option: string) => ({ value: option, label: t(option) || 'Все' })),
            objectLocations: ['', ...res.objectLocations].map((option: string) => ({ value: option, label: t(option) || 'Все' })),
            projectNames: ['', ...res.projectNames].map((option: string) => ({ value: option, label: t(option) || 'Все' })),
            examLocations: ['', ...res.examLocations].map((option: string) => ({ value: option, label: t(option) || 'Все' })),
            minDate: res.minDate[0],
            maxDate: res.maxDate[0]
          });
        })
      })
    }
  }, [kato, filters])

  return (
    <TopTenPsdContainer>
      <Filters>
        <FilterItem>
          {getText('период')}
          <div className="dates">
            <input type="date" min={filterDict.minDate} max={filterDict.maxDate} value={filters['startDate']} onChange={(e) => setFilters({ ...filters, startDate: e.target.value })} />
            -
            <input type="date" min={filterDict.minDate} max={filterDict.maxDate} value={filters['endDate']} onChange={(e) => setFilters({ ...filters, endDate: e.target.value })} />
          </div>
        </FilterItem>

        {
          filterNames.map((filterName, index) => (
            <FilterItem key={index}>
              {getText(t('psd-page.' + filterName))}

              <Select
                options={filterDict[keyToListKey[filterName]] as any[]}
                value={(filterDict[keyToListKey[filterName]] as any[]).find((option) => option.value === filters[filterName])}
                onChange={(option) => setFilters({ ...filters, [filterName]: option?.value })}
                isClearable
                noOptionsMessage={() => t('нет данных')}
              />

            </FilterItem>
          ))
        }
      </Filters>

      {
        client && client.data && client.options && <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>ТОП - 10 заказчиков ПСД</Text>
              <Text color='#818689' fontWeight={400} fontSize='0.75rem'>количество ПСД</Text>
            </div>
          </div>
          <div className="body">
            <Bar data={client.data} options={client.options} plugins={[ChartDataLabels]} />
          </div>
        </BasicStatsItem>
      }
      {
        category && category.data && category.options && <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>ПСД по категории объекта</Text>
              <Text color='#818689' fontWeight={400} fontSize='0.75rem'>количество ПСД</Text>
            </div>
          </div>
          <div className="body">
            <Bar data={category.data} options={category.options} plugins={[ChartDataLabels]} />
          </div>
        </BasicStatsItem>
      }
    </TopTenPsdContainer>
  )
}

export default TopTenPsd