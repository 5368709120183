import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text } from '../../text.component';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BasicStatsItem } from '../index.styles';
import { topFiveByActivityTypeOptions, topFiveByInvestmentSourceOptions } from './chart-options';
import { useParams } from 'react-router-dom';
import { getAllPsdData } from '../../../requests/kato.request';
import { months } from '../invest-projects.component';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

const Filter = styled.div`
  display: flex;
  gap: 2.5rem;
  height: 50px;
  align-items: center;
  padding: 0 1.25rem;

  .years-checkboxes {
    display: flex;  
    gap: 1.25rem;
    align-items: center;  
  }

  .container {
    display: flex;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-items: center;
    gap: 0.5rem;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    display: inline-block;
    height: 18px;
    width: 18px;
    border-radius: 3px;
    border: 1px solid rgba(219, 220, 224, 1);
    position: relative;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #118f84;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 6px;
    top: 2px;
    transform: translate(-50%, -50%);
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .district-select {
    display: flex;
    gap: 0.625rem;
    align-items: center;

    select {
      height: 32px;
      width: 375px;
      border: 1px solid #DBDCE0;
      border-radius: 5px;
      font-size: 0.75rem;

      &:focus {
        outline: none;
      }

      &:placeholder {
        color: #818689;
      }
    }
  }

  .date {
    display: flex;
    gap: 0.3125rem;
    align-items: center;
    justify-self: flex-end;
    margin-left: auto;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.625rem;
  padding: 0.625rem 0.625rem 0.625rem 1.25rem;
  grid-auto-rows: minmax(0, 420px);
  height: 100%;

  > div {
    width: 100%; 
    max-height: 420px; 

    .body {
      max-height: 100%;
    }

    :last-child {
      grid-column: span 2;
    }
  }
`

const years = ['all-years', '2022', '2023', '2024'];

const financingSourceColors: Record<string, string> = {
  'NON_GOVERNMENTAL_INVESTMENT': '#5BC7D9',
  'QUASI_GOVERNMENTAL_INVESTMENT': '#92D050',
  'GOVERNMENTAL_INVESTMENT': '#118F84',
};

const financingSourceLabels: Record<string, string> = {
  'NON_GOVERNMENTAL_INVESTMENT': 'Частные',
  'QUASI_GOVERNMENTAL_INVESTMENT': 'Квазигосударственные',
  'GOVERNMENTAL_INVESTMENT': 'Государственные',
};

export interface IChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    barThickness: number;
    borderRadius: number;
  }[];
}

export interface IChart {
  data: IChartData;
  options: any;
}

export const getFormattedDate = () => {
  const date = new Date();
  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();

  return `${day} ${months[month]} ${year}`;
}

const AllPsdTab = () => {
  const { t } = useTranslation();
  const { kato } = useParams()
  const [selectedYears, setSelectedYears] = useState<string[]>(['2024']);
  const [process, setProcess] = useState<IChart>();
  const [category, setCategory] = useState<IChart>();
  const [financing, setFinancing] = useState<IChart>();

  const handleCheckboxChange = (year: string) => {
    if (year === 'all-years') {
      if (selectedYears.length === years.length - 1) {
        setSelectedYears([]);
      } else {
        setSelectedYears(years.filter(y => y !== 'all-years'));
      }
    } else {
      let updatedSelectedYears = [...selectedYears];

      if (selectedYears.includes(year)) {
        updatedSelectedYears = updatedSelectedYears.filter(y => y !== year);
      } else {
        updatedSelectedYears.push(year);
      }

      const yearGroup = ['2022', '2023', '2024'];
      const allGroupSelected = yearGroup.every(y => updatedSelectedYears.includes(y));

      if (allGroupSelected) {
        updatedSelectedYears = [...new Set([...updatedSelectedYears, ...yearGroup])];
      }

      setSelectedYears(updatedSelectedYears);
    }
  };

  useEffect(() => {
    if (kato) {
      const years = selectedYears.length === 0 || selectedYears.length === 3 ? ['0'] : selectedYears;

      getAllPsdData(+kato, years).then(res => {
        const process = res.process?.sort((a, b) => b.count - a.count).splice(0, 5);
        const category = res.category.sort((a, b) => b.count - a.count).splice(0, 5);
        const financing = res.financing

        const processOptions = {
          ...topFiveByActivityTypeOptions,
          scales: {
            ...topFiveByActivityTypeOptions.scales,
            x: {
              ...topFiveByActivityTypeOptions.scales.x,
              max: Math.max(...process.map(p => p.count)) * 1.25,
              beginAtZero: true,
            }

          }
        }

        const categoryOptions = {
          ...topFiveByActivityTypeOptions,
          scales: {
            ...topFiveByActivityTypeOptions.scales,
            x: {
              ...topFiveByActivityTypeOptions.scales.x,
              max: Math.max(...category.map(p => p.count)) * 1.25,
              beginAtZero: true,
            }
          }
        }

        const financingDatasets = Object.keys(financingSourceColors).map(source => ({
          label: financingSourceLabels[source],
          data: Array(12).fill(null),
          backgroundColor: financingSourceColors[source],
          barThickness: 30,
          borderRadius: 5,
        }));

        financing.forEach(item => {
          const dataset = financingDatasets.find(d => d.label === financingSourceLabels[item.financingSource]);
          if (dataset) {
            dataset.data[item.month - 1] = item.count;
          }
        });

        setProcess({
          data: {
            labels: process.map(p => p.process),
            datasets: [{
              label: 'Количество ПСД',
              data: process.map(p => p.count),
              backgroundColor: "#118F84",
              barThickness: 9,
              borderRadius: 2,
            }]
          },
          options: processOptions
        })

        setCategory({
          data: {
            labels: category.map(p => p.category),
            datasets: [{
              label: 'Количество ПСД',
              data: category.map(p => p.count),
              backgroundColor: "#118F84",
              barThickness: 9,
              borderRadius: 2,
            }]
          },
          options: categoryOptions
        })

        setFinancing({
          data: {
            labels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            datasets: financingDatasets
          },
          options: topFiveByInvestmentSourceOptions
        })
      });
    }
  }, [kato, selectedYears]);

  return (
    <>
      <Filter>
        <div className='years-checkboxes'>
          {years.map((year) => (
            <label className='container'>
              <input
                type="checkbox"
                checked={year === 'all-years' ? selectedYears.length === years.length - 1 : selectedYears.includes(year)}
                onChange={() => handleCheckboxChange(year)}
                id={year}
                name={year}
              />
              <span className="checkmark" />
              <Text
                fontSize='0.75rem'
                fontWeight={selectedYears.includes(year) || selectedYears.length === years.length - 1 ? 600 : 400}
                color={selectedYears.includes(year) || selectedYears.length === years.length - 1 ? '#118f84' : 'rgba(129, 134, 137, 1)'}
              >
                {t('psd-page.' + year)}
              </Text>
            </label>
          ))}
        </div>

        {/* <div className="district-select">
          <Text fontSize='0.75rem' fontWeight={400} color='#202224'>Район:</Text>
          <select>
            <option value="all" selected disabled>Все районы</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div> */}

        <div className="date">
          <Text fontSize='0.75rem' fontWeight={400} color='#818689'>отчет на</Text>
          <Text fontSize='0.75rem' fontWeight={400} color='#202224'>{`${getFormattedDate()}`}</Text>
        </div>
      </Filter>

      <StyledGrid>
        {
          process && process.data && process?.options && <BasicStatsItem>
            <div className="title">
              <div className="left">
                <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>ТОП - 5 ПСД</Text>
                <Text color='#818689' fontWeight={400} fontSize='0.75rem'>по виду деятельности</Text>
              </div>
            </div>
            <div className="body">
              <Bar data={process.data} options={process.options} plugins={[ChartDataLabels]} />
            </div>
          </BasicStatsItem>
        }
        {
          category && category.data && category.options && <BasicStatsItem>
            <div className="title">
              <div className="left">
                <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>ТОП - 5 ПСД</Text>
                <Text color='#818689' fontWeight={400} fontSize='0.75rem'>по виду объектов</Text>
              </div>
            </div>
            <div className="body">
              <Bar data={category.data} options={category.options} plugins={[ChartDataLabels]} />
            </div>
          </BasicStatsItem>
        }
        {
          financing && financing.data && financing.options && <BasicStatsItem>
            <div className="title">
              <div className="left">
                <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>ПСД по виду инвестиций</Text>
              </div>
            </div>
            <div className="body">
              <div className="psd-chart-container">
                <Bar data={financing.data} options={financing.options} plugins={[ChartDataLabels]} />
              </div>
            </div>
          </BasicStatsItem>
        }
      </StyledGrid>
    </>
  );
};

export default AllPsdTab;
