import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Text } from '../../text.component'
import { useTranslation } from 'react-i18next'
import Education from '../education.component'
import { useParams } from 'react-router-dom'
import Health from '../health.component'
import CultureSport from '../culture-sport.component'
import WaterSupply from '../water-supply.component'
import Internet from '../internet.component'
import AccessRoad from '../access-road.components'
import VillageRoad from '../village-road.component'
import Lighting from '../lighting.component'

const InfrastructureProjectsContainer = styled.div`
  min-height: 30rem;
  .header { 
    display: flex;
    gap: 0.625rem;
    padding: 0.625rem 1.25rem;
    background-color: #fff;
  }

  .list-body {
    background-color: #f6f6f6;
  }

  .tab { 
    cursor: pointer;
    height: 1.8125rem;
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent;
    padding: 0 0.625rem ;

    &.selected {
      border-bottom: 2px solid var(--primary-color);
    }
  }
`

const tabs = ['education', 'health', 'culture', 'water-supply', 'internet-communication', 'access-road', 'village-road', 'lighting']

const InfrastructureProjectsTab = () => {
  const { t } = useTranslation();
  const { kato } = useParams()

  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [content, setContent] = useState<any>(null);
  const ref = useRef(null)

  useEffect(() => {
    if (selectedTab && kato) {
      switch (selectedTab) {
        case tabs[0]:
          setContent(<Education kato={+kato} contentRef={ref} />);
          break;
        case tabs[1]:
          setContent(<Health kato={+kato} contentRef={ref} />);
          break;
        case tabs[2]:
          setContent(<CultureSport kato={+kato} contentRef={ref} />);
          break;
        case tabs[3]:
          setContent(<WaterSupply kato={+kato} contentRef={ref} />);
          break;
        case tabs[4]:
          setContent(<Internet kato={+kato} contentRef={ref} />);
          break;
        case tabs[5]:
          setContent(<AccessRoad kato={+kato} contentRef={ref} />);
          break;
        case tabs[6]:
          setContent(<VillageRoad kato={+kato} contentRef={ref} />);
          break;
        case tabs[7]:
          setContent(<Lighting kato={+kato} contentRef={ref} />);
          break;
        default:
          setContent(<Education kato={+kato} contentRef={ref} />);
          break;
      }
    }
  }, [selectedTab, kato])

  return (
    <InfrastructureProjectsContainer>
      <div className="header">
        {tabs.map(tab => (
          <Text
            className={`tab ${selectedTab === tab ? 'selected' : ''}`}
            fontWeight={selectedTab === tab ? 600 : 400}
            color={selectedTab === tab ? 'var(--primary-color)' : '#818689'}
            onClick={() => setSelectedTab(tab)}
            fontSize='0.75rem'
          >
            {t(`report_types.${tab}`)}
          </Text>
        ))}
      </div>

      {kato && content && <div className="list-body" ref={ref}>
        {content}
      </div>}
    </InfrastructureProjectsContainer>
  )
}

export default InfrastructureProjectsTab