import React, { ChangeEvent } from 'react'
import { Field } from 'formik';
import { FooterButton, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock, FormBlockButton } from '../insfrastructureProjects/infrastructure.styles'
import { AddField } from '../../assets/icons';
import { addLink, lowerAndTrim, t } from '../../utils/helpers.utils'
import { ITempVal } from '../insfrastructureProjects/education-form.component';

interface IProps {
  formData: any;
  setFormData: (formData: any) => void;
  setFieldValue: (field: string, value: any) => void;
  lang: 'Ru' | 'Kz';
  onSave: (data: any) => void;
  setTempVal: (data: any) => void;
  tempVal: ITempVal;
  onDelete: (key: string) => void;
  type: string;
  errors: any;
}

const LinksForm: React.FC<IProps> = ({ formData, setFormData, setFieldValue, lang, setTempVal, tempVal, onDelete, type, errors }) => {

  const handleCancelAdd = () => {
    setTempVal({ isAdding: false, labelKz: '', labelRu: '', valueKz: '', valueRu: '', isAddingText: false, textRu: '', textKz: '', labelError: false, valueError: false, textError: false, isAddingLink: false, link: '', linkLabelRu: '', linkLabelKz: '', linkError: false });
  }

  const onAdd = () => {
    addLink(tempVal, setTempVal, formData, setFormData)
  }

  return (
    <FormBlock>
      <div className="title">{t(`form.links`, lang)}</div>

      {formData.additionalFields && Object.keys(formData.additionalFields).length > 0 && Object.keys(formData.additionalFields).filter((key) => !['ru', 'kz'].includes(key) && key.startsWith('link')).map((key) => {
        return (
          <div className="row sb" key={key}>
            <FormGroup>
              <label htmlFor={`additionalFields.${key}.linkLabel${lang}`}>{formData.additionalFields[key][`linkLabel${lang}`]}</label>
              <Field
                id={`additionalFields.${key}`}
                name={`additionalFields.${key}`}
                value={formData.additionalFields[key].link}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(`additionalFields.${key}.link`, lowerAndTrim(e.target.value))
                    setFormData({ ...formData, additionalFields: { ...formData.additionalFields, [key]: { ...formData.additionalFields[key], link: e.target.value } } })
                  }}
                className={errors[key] ? 'error' : ''}
              />
            </FormGroup>
            <FooterButton variant='delete' onClick={() => onDelete(key)}>x</FooterButton>
          </div>
        )
      })}

      {tempVal.isAddingLink && <div className="row sb">
        <FormGroup>
          <input type="text"
            value={tempVal[`linkLabel${lang}`]}
            onChange={(e) => setTempVal((prev: ITempVal) => ({ ...prev, [`linkLabel${lang}` as keyof ITempVal]: lowerAndTrim(e.target.value) }))}
            className={tempVal.linkError ? 'error' : ''}
          />
          <input type="text"
            value={tempVal[`link`]}
            onChange={(e) => setTempVal((prev: ITempVal) => ({ ...prev, [`link`]: e.target.value }))}
            className={tempVal.valueError ? 'error' : ''}
          />
        </FormGroup>
      </div>}

      <div className="add-info__buttons">
        {type !== 'agriculture_producers' && <>
          {tempVal.isAddingLink
            ? <>
              <FormBlockButton onClick={onAdd}>{t('save', lang)}</FormBlockButton>
              <FormBlockButton onClick={handleCancelAdd}>{t('cancel', lang)}</FormBlockButton>
            </>
            : <FormBlockButton onClick={() => setTempVal((prev: ITempVal) => ({ ...prev, isAddingLink: true }))}>
              <AddField /> {t('form.add_field', lang)}
            </FormBlockButton>
          }</>}
      </div>

    </FormBlock>
  )
}

export default LinksForm