import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { toast } from 'react-toastify';
import { checkAddKeys, checkTempVal, checkVal, lowerAndTrim, t } from '../../utils/helpers.utils';

import { createObject, updateObject } from '../../requests/supervisor.request';
import { getProject } from '../../requests/snp.request';

import { Wrapper, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock } from './infrastructure.styles';

import ImageGrid from './image-grid.component';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import DocumentationForm from '../formComponents/documentation-form.component';
import SportSections from '../formComponents/sport-sections.component';
import FormFooter from './footer.component';
import FieldsComponent from './fields.components';

import { IProject } from '../../interfaces/snp.interface';
import { educationOptions, options, requirementsOptions } from '../../constants/snp.constant';

import 'react-toastify/dist/ReactToastify.css';
import Tabs from './tabs.component';
import LinksForm from '../formComponents/links-form.component';

const initialFormData = {
  "id": 0,
  "kato": 0,
  "structureNameRu": "",
  "structureNameKz": "",
  "constructionYear": 0,
  "factInfo": 0,
  "power": 0,
  "quality": "GOOD",
  "requirements": {
  },
  "documentationStatus": "",
  "workDuration": "",
  "sportSections": {},
  "photoIds": [
    0
  ],
  "requiredFund": {},
  "additionalFields": {
    'ru': {},
    'kz': {}
  }
}

export const errMsg: any = {
  nameRu: 'objectName',
  nameKz: 'objectName',
  constructionYear: 'constructionYear',
  factInfo: 'factInfo',
  isSchool: 'isSchool',
  factCondition: 'factCondition',
  sportSections: 'sportSections',
  requirements: 'repair-required',
  requirementsKz: 'exactly',
  requirementsRu: 'exactly',
  power: 'power',
  files: 'files',
  documentationStatus: "documentationStatus",
  workStart: "date",
  workEnd: "date",
  requiredFund: "requiredFund",
  requiredFundSourceRu: "requiredFundSource",
  requiredFundSourceKz: "requiredFundSource",
  requiredFundAmount: "requiredFundAmount",
  subscriberNumber: "subscriberNumber",
  totalStreetNumber: "totalStreetNumber",
  totalStreetLength: "totalStreetLength",
  lightenedStreetLength: "lightenedStreetLength",
  repairRequired: "repair-required",
  illuminated: "illuminated",
  illuminatedStreetLength: "illuminatedStreetLength"
}

export const documentationKeys = [
  "documentationStatus",
  "workStart",
  "workEnd",
  "requiredFundSourceRu",
  "requiredFundSourceKz",
  "requiredFundAmount"
];

export const skipList: string[] = [
  "nameEn",
  "qualityRu",
  "qualityKz",
  "lastMajorRenovation",
  "additionalFields",
  "files",
  "requirementsKz",
  "requirementsRu",
  "repairRequired",
  "requirements",
  ...documentationKeys
];

export interface ITempVal {
  isAdding: boolean,
  labelKz: string,
  labelRu: string,
  valueKz: string,
  valueRu: string,
  isAddingText: boolean,
  textKz: string,
  textRu: string,
  labelError: boolean,
  valueError: boolean,
  textError: boolean,
  isAddingLink: boolean,
  link: string,
  linkLabelRu: string,
  linkLabelKz: string,
  linkError: boolean
}

export interface ITempFund {
  labelKz: string,
  labelRu: string,
  value: string,
  isAdding: boolean,
  labelError: boolean,
  valueError: boolean,
  streetId?: number | null
}

const EducationForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [tempVal, setTempVal] = useState<ITempVal>({
    isAdding: false,
    labelKz: '',
    labelRu: '',
    valueKz: '',
    valueRu: '',
    isAddingText: false,
    textKz: '',
    textRu: '',
    labelError: false,
    valueError: false,
    textError: false,
    isAddingLink: false,
    link: '',
    linkLabelRu: '',
    linkLabelKz: '',
    linkError: false
  });

  const [tempFund, setTempFund] = useState<ITempFund>({
    labelKz: '',
    labelRu: '',
    value: '',
    isAdding: false,
    labelError: false,
    valueError: false,
    streetId: null
  })

  const [isAddingSportSection, setIsAddingSportSection] = useState(false);
  const [newSportSection, setNewSportSection] = useState<{ label: string, value: string }>({ label: '', value: '' });
  const [sportSections, setSportSections] = useState<any>({});
  const handleSave = (values: IProject) => {
    setErrors({})
    setTempVal({ ...tempVal, textError: false, labelError: false, valueError: false })
    setTempFund({ ...tempFund, labelError: false, valueError: false })

    let data: any = {
      ...values,
      additionalFields: { ...formData.additionalFields },
      sportSections: { ...values.sportSections, ...formData.sportSections },
      requiredFund: { ...values.requiredFund, ...formData.requiredFund },
      documentationStatus: values.documentationStatus === "" ? null : values.documentationStatus,
    }

    const keys = Object.keys(data).filter((key: string) => !skipList.includes(key));

    for (const key of keys) {
      if (!checkVal(data[key]) && typeof data[key] !== 'boolean') {
        setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
        toast.error(t(`errors.${errMsg[key]}`, language))
        return
      }
    }

    if (data.constructionYear > 2030 || data.constructionYear <= 0) {
      setErrors((prev: any) => ({ ...prev, constructionYear: true }))
      toast.error(t(`errors.yearRange`, language))
      return
    }

    if (formData.files && formData.files.length === 0) {
      setErrors((prev: any) => ({ ...prev, files: true }))
      toast.error(t(`errors.files`, language))
      return
    }

    const updatedData = checkTempVal(tempVal, setTempVal, formData, setFormData, data);

    if (!updatedData) {
      return;
    }

    data = updatedData;

    checkAddKeys(data, setErrors);

    if (tempFund.isAdding) {
      if (!checkVal(tempFund.labelKz) || !checkVal(tempFund.labelRu)) {
        setTempFund((prev: any) => ({ ...prev, labelError: true }))
        toast.error(t(`errors.additional-field`, language))
        return
      }

      if (!checkVal(tempFund.value)) {
        setTempFund((prev: any) => ({ ...prev, valueError: true }))
        toast.error(t(`errors.additional-value`, language))
        return
      }

      data = {
        ...data,
        requiredFund: addFund()
      }
    }

    const fund_keys = data.requiredFund && Object.keys(data.requiredFund).filter((key: string) => !['ru', 'kz'].includes(key));

    if (fund_keys && data.requirements && data.requirements !== 'NOT_NEEDED' && data.documentationStatus === 'HAVE') {
      for (const key of fund_keys) {
        if (!checkVal(data.requiredFund[key].value)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      }
    }

    updateObject('education', data)
      .then(() => toast.success(t(`toast.save_success`, language)))
      .catch(() => toast.error(t(`toast.save_error`, language)))
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('education', objectId).then(res => {
      setFormData({ ...res.infrastructureEntity, sportSections: { ...res.infrastructureEntity.sportSections, ...sportSections } })
      setImages(res.images)
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('education', val).then(res => {
        navigate(`/admin/${val}/infrastructure-projects/education/${res.id}`);
      })
    }
  }, [navigate, objectId, kato])

  const deleteField = (key: string) => {
    const { [key]: deleted, ...rest } = formData.additionalFields;
    setFormData({ ...formData, additionalFields: rest })
    setTempVal({ ...tempVal, isAdding: false, labelError: false, valueError: false })
    updateObject('EDUCATION', { ...formData, additionalFields: rest })
      .then(() => loadForm())
  }

  const addFund = () => {
    if (!checkVal(tempFund.labelKz) || !checkVal(tempFund.labelRu)) {
      setTempFund((prev: any) => ({ ...prev, labelError: true }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempFund.value)) {
      setTempFund((prev: any) => ({ ...prev, valueError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const { labelKz, labelRu, value } = tempFund;

    const field = { ...formData.requiredFund, [+new Date()]: { labelKz, labelRu, value } }

    const body = {
      ...formData,
      requiredFund: field
    }

    setFormData(body)
    setTempFund({ labelRu: '', labelKz: '', value: '', isAdding: false, labelError: false, valueError: false })

    return field;
  }

  const deleteFund = (key: string) => {
    const { [key]: deleted, ...rest } = formData.requiredFund;

    const body = {
      ...formData,
      requiredFund: rest
    }

    setFormData(body)
    updateObject('EDUCATION', body).then(() => {
      loadForm()
    })
  }

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return (
      <>
        <FormGroup>
          <label className='required' htmlFor={`name${lang}`}>{t(`form.structureName`, lang)}</label>
          <Field
            id={`name${lang}`}
            name={`name${lang}`}
            placeholder={t(`form.structureName`, lang)}
            onChange={(e: any) => setFieldValue(`name${lang}`, lowerAndTrim(e.target.value))}
            className={errors['objectName'] ? 'error' : ''}
          />
        </FormGroup>

        <FormBlock>
          <div className="grid">
            <FormGroup>
              <label className='required' htmlFor={`constructionYear`}>{t(`form.constructionYear`, lang)}</label>
              <Field
                id={`constructionYear`}
                name={`constructionYear`}
                placeholder={t(`form.constructionYear`, lang)}
                as="input"
                type="number"
                className={errors['constructionYear'] ? 'error' : ''}
              />
            </FormGroup>
            <FormGroup>
              <label className='required' htmlFor={`factInfo`}>{t(`form.fact-education`, lang)}</label>
              <Field
                id={`factInfo`}
                name={`factInfo`}
                placeholder={t(`form.factInfo`, lang)}
                as="input"
                type="number"
                className={errors['factInfo'] ? 'error' : ''}
              />
            </FormGroup>
            <FormGroup>
              <label className='required' htmlFor={`power`}>{t(`form.project-power`, lang)}</label>
              <Field
                id={`power`}
                name={`power`}
                placeholder={t(`form.power`, lang)}
                as="input"
                type="number"
                className={errors['power'] ? 'error' : ''}
              />
            </FormGroup>
          </div>
        </FormBlock>

        <FormBlock>
          <FormGroup>
            <label className='required' htmlFor={`isSchool`}>{t(`object-type`, lang)}</label>
            <select
              value={formData.isSchool}
              onChange={(e) => {
                setFieldValue(`isSchool`, e.target.value);
                setFormData({ ...formData, isSchool: e.target.value })
              }}
              className={errors['isSchool'] ? 'error' : ''}

            >
              <option value="" selected hidden></option>
              {
                educationOptions.map((option) => (
                  <option key={option.value} value={option.value}>{t(option.label.toLowerCase(), lang)}</option>
                ))
              }
            </select>
          </FormGroup>
        </FormBlock>

        <FormBlock>
          <FormGroup>
            <label className='required' htmlFor={`factCondition`}>{t(`form.factCondition`, lang)}</label>
            <select
              value={formData.factCondition}
              onChange={(e) => {
                setFieldValue(`factCondition`, e.target.value);
                setFormData({ ...formData, factCondition: e.target.value })
              }}
              className={errors['factCondition'] ? 'error' : ''}
            >
              <option value="" selected hidden></option>
              {
                options.map((option) => (
                  <option key={option.value} value={option.value}>{t(option.label.toLowerCase(), lang)}</option>
                ))
              }
            </select>
          </FormGroup>
        </FormBlock>

        <FormBlock>
          <FormGroup>
            <label htmlFor={`requirements`}>{t(`requirementsOptions.required`, lang)}</label>
            <select
              name="requirements"
              id="requirements"
              onChange={(e: any) => {
                setFieldValue('requirements', e.target.value);
                setFormData({ ...formData, requirements: e.target.value })
              }}
              defaultValue={""}
              value={formData.requirements}
              className={errors['requirements'] ? 'error' : ''}
            >
              <option value="" hidden></option>
              {requirementsOptions.map((option) => (
                <option value={option}>{t(`requirementsOptions.${option}`, lang)}</option>
              ))}
            </select>
          </FormGroup>

          {
            formData.requirements && formData.requirements !== "" && formData.requirements !== 'NOT_NEEDED' && <>
              <FormGroup>
                <label
                  htmlFor={`requirements${lang}`}>
                  {t(`requirementsOptions.exactly`, lang)}
                </label>
                <div className='exactly-required'>
                  <div>
                    {t('required', lang)}:
                  </div>
                  <Field
                    id={`requirements${lang}`}
                    name={`requirements${lang}`}
                    placeholder={t(`form.requirements${lang}`, lang)}
                    className={errors['exactly'] ? 'error' : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setFormData({ ...formData, [`requirements${lang}`]: e.target.value.toLowerCase() })
                      setFieldValue(`requirements${lang}`, e.target.value.toLowerCase())
                    }}
                  />
                </div>

              </FormGroup>

              <FormGroup>
                <label htmlFor={`lastMajorRenovation`}>{t(`requirementsOptions.lastMajorRenovation`, lang)}</label>
                <Field
                  id={`lastMajorRenovation`}
                  name={`lastMajorRenovation`}
                  placeholder={t(`requirementsOptions.lastMajorRenovation`, lang)}
                  as="input"
                  type="date"
                  value={formData.lastMajorRenovation ? formData.lastMajorRenovation.split('T')[0] : null}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, lastMajorRenovation: e.target.value })
                    setFieldValue('lastMajorRenovation', e.target.value)
                  }}
                  className={errors.lastMajorRenovation ? 'error' : ''}
                />
              </FormGroup>
            </>
          }
        </FormBlock>

        {
          formData.requirements && formData.requirements !== ""
          && formData.requirements !== 'NOT_NEEDED'
          && <DocumentationForm
            formData={formData}
            setFormData={setFormData}
            lang={lang}
            setFieldValue={setFieldValue}
            type='education'
            errors={errors}
            onAdd={addFund}
            tempFund={tempFund}
            setTempFund={setTempFund}
            onDelete={deleteFund}
          />
        }

        <SportSections
          formData={formData}
          setFormData={setFormData}
          lang={lang}
          setFieldValue={setFieldValue}
          type='education'
          onSave={setSportSections}
          isAddingSportSection={isAddingSportSection}
          setIsAddingSportSection={setIsAddingSportSection}
          newSportSection={newSportSection}
          setNewSportSection={setNewSportSection}
          loadForm={loadForm}
        />

        <AdditionalFieldsForm
          formData={formData}
          setFormData={setFormData}
          lang={lang}
          setFieldValue={setFieldValue}
          onSave={handleSave}
          setTempVal={setTempVal}
          errors={errors}
          tempVal={tempVal}
          onDelete={deleteField}
          type='education'
        />

        <LinksForm
          formData={formData}
          setFormData={setFormData}
          lang={lang}
          setFieldValue={setFieldValue}
          onSave={handleSave}
          setTempVal={setTempVal}
          errors={errors}
          tempVal={tempVal}
          onDelete={deleteField}
          type='education'
        />
      </>
    )
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  return (
    <Wrapper ref={wrapperRef}>
      <Tabs />
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FieldsComponent renderFields={renderFields} setFieldValue={setFieldValue} />
                <FormFooter type='education' formId={formData.id} />
              </Form>
            )}
          </Formik>
        </>
      }

      <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'education'}
        images={images}
        lang={language}
      />
    </Wrapper >
  )
}

export default EducationForm