import React from 'react'
import { BasicStatsItem } from '../index.styles'

const Tourism = () => {
  const link = 'https://app.powerbi.com/view?r=eyJrIjoiMzllNWY5M2ItZmNmMy00ZTE3LWE2OGQtZTJlZWVjOTliM2MyIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9'
  return (
    <BasicStatsItem
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        flex: '1',
        minHeight: 'calc(100vh - 4.4rem)'
      }}>
      <iframe title="tourism iframe" src={link} />
    </BasicStatsItem>
  )
}

export default Tourism