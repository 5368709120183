import React, { useState } from 'react'
import { SERContainer } from './fullscreen/index.styles'
import { useTranslation } from 'react-i18next';
import InfrastructureProjectsTab from './infrastructure-projects/infrastructure-projects-tab.component';
import VillageImprovement from './village-improvement.component';
import Investment from './investment.component';

const tabs = ['infrastructure-projects', 'village-improvement', 'investment-projects',]

const DigitalPassport = () => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  return (
    <SERContainer>
      <div className="tabs">
        {tabs.map((tab: string) => (
          <div
            key={tab}
            className={selectedTab === tab ? 'tab active' : 'tab'}
            onClick={() => setSelectedTab(tab)}
          >
            {t('digital-passport-component.' + tab)}
          </div>
        ))}
      </div>

      {selectedTab === tabs[0] && <InfrastructureProjectsTab />}
      {selectedTab === tabs[1] && <VillageImprovement />}
      {selectedTab === tabs[2] && <Investment />}


    </SERContainer>
  )
}

export default DigitalPassport