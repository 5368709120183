import { useEffect, useState } from 'react'
import { InfrastructureTab, InfrastructureTabs } from './infrastructure.styles'
import { reportTypes } from '../../constants/navlinks.constant'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const Tabs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedReport, setSelectedReport] = useState<any>(null)
  const { kato } = useParams<any>();


  const handleClick = (report: string) => {
    setSelectedReport(report)
    navigate(`/admin/${kato}/infrastructure-projects/` + report)
  }

  useEffect(() => {
    const location = window.location.pathname.split('/');
    const selected = location[location.length - 2]
    selected && setSelectedReport(selected);
  }, [window.location.pathname])


  return (
    <InfrastructureTabs>
      {
        reportTypes.map(report =>
          <InfrastructureTab
            key={report}
            selected={selectedReport === report}
            onClick={() => handleClick(report)}>{t(`report_types.${report}`)}</InfrastructureTab>)
      }
    </InfrastructureTabs>
  )
}

export default Tabs