import React, { FC, useContext, useEffect, useState } from 'react'
import { IDistrictIncomeList } from '../../interfaces/snp.interface'
import { BasicStatsItem } from './index.styles'
import { Text } from '../text.component'
import { useTranslation } from 'react-i18next'
import { Expand, ExpandCard, ExpandWhite } from '../../assets/icons'
import ThemeContext, { Theme } from '../../contexts/theme.context'
import { checkVal, months, OBLAST_KATO } from '../../utils/helpers.utils'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);


interface IProps {
  data: IDistrictIncomeList,
  handleFullScreen: (obj: any) => void,
  kato: string
}

const DistrictIncome: FC<IProps> = ({ data, handleFullScreen, kato }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext)
  const [chartData, setChartData] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState<any[]>([]);

  const handleResize = () => {
    if (data) {
      let fontSize = 16;

      if (window.innerWidth <= 1500) {
        fontSize = 12;
      } else if (window.innerWidth > 1500) {
        fontSize = 15;
      }

      const options = (index: number) => ({
        indexAxis: 'y',
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'rect',
            },
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            color: theme === Theme.LIGHT ? '#118F84' : '#ffc803', // Optionally set the color of the labels,
            font: {
              size: 16,
              weight: 'bold'
            }
          },
          title: {
            display: true,
            text: index === 0 ? 'Плановый период на 01.08.2024' : 'Годовой показатель',
            font: {
              size: 14,
              weight: 'thin',
              color: '#202224'
            }
          }
        },
        scales: {
          x: {
            beginAtZero: true,
            display: false,
            grid: {
              display: false
            },
            min: 0,
            max: index === 0 ? Math.max(data.periodPlan, data.periodFact) * 1.35 : Math.max(data.annualPlan, data.annualFact) * 1.35,
          },
          y: {
            grid: {
              display: false
            },
            ticks: {
              color: theme === Theme.LIGHT ? '#202224' : '#fff',
              font: {
                size: fontSize,
              }
            }
          }

        },
        responsive: true,
        maintainAspectRatio: false
      })

      const periodDatasets = [
        {
          label: '',
          data: [data.periodPlan, null],
          backgroundColor: '#226AC3',
          barThickness: 7,
          borderRadius: 10,
        },
        {
          label: '',
          data: [null, data.periodFact],
          backgroundColor: '#92D050',
          barThickness: 7,
          borderRadius: 10,
        },
      ]

      const annualDatasets = [
        {
          label: '',
          data: [data.annualPlan, null],
          backgroundColor: '#226AC3',
          barThickness: 7,
          borderRadius: 10,
        },
        {
          label: '',
          data: [null, data.annualFact],
          backgroundColor: '#92D050',
          barThickness: 7,
          borderRadius: 10,
        },
      ]

      setChartData([
        {
          labels: ['План', 'Факт'],
          datasets: periodDatasets,
        },
        {
          labels: ['План', 'Факт'],
          datasets: annualDatasets,
        }
      ])

      setChartOptions([options(0), options(1)])
    }
  }

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [theme, data]);

  if (Object.keys(data).some((key: string) => !checkVal(data[key as keyof IDistrictIncomeList])) || chartData.length === 0 || chartOptions.length === 0) {
    return <></>
  }

  return (
    <BasicStatsItem theme={theme} id="district-income">
      <div className="title">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{+kato === OBLAST_KATO ? t(`district-income.oblast`) : t(`district-income.title`)}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>на 1 {months[new Date().getMonth()]} 2024 </Text>
        </div>
        <div className="right">
          <span className='unit-container' >
            {/* {
              theme === Theme.LIGHT
                ? <Expand onClick={() => handleFullScreen({ isOpen: true, type: 'DistrictIncome' })} width={12} height={12} />
                : <ExpandWhite onClick={() => handleFullScreen({ isOpen: true, type: 'DistrictIncome' })} width={12} height={12} />
            } */}

            <ExpandCard onClick={() => handleFullScreen && handleFullScreen({ isOpen: true, type: 'DistrictIncome' })} />
          </span>
        </div>
      </div>

      <div className="body">
        <div className="district-income-grid">
          <div className='chart-container' >
            <Bar data={chartData[0]} options={chartOptions[0]} />
          </div>
          <div>
            <Text fontSize='0.75rem' color='var(--text-color)' fontWeight={400}>Исполнение</Text>
            <Text fontSize='1.5rem' color='#C57979' fontWeight={700}>{data.periodImplementation.toFixed(1)}%</Text>
          </div>
          <div className='chart-container' >
            <Bar data={chartData[1]} options={chartOptions[1]} />
          </div>
          <div>
            <Text fontSize='0.75rem' color='var(--text-color)' fontWeight={400}></Text>
            <Text fontSize='1.5rem' color='#C57979' fontWeight={700}>{data.annualImplementation.toFixed(1)}%</Text>
          </div>
        </div>
      </div>
    </BasicStatsItem>
  )
}

export default DistrictIncome