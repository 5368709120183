import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CustomSelect from './select.component'
import Search from './search.component'

import { ArrowLeft } from '../../assets/icons'
import { HeaderWrapper, BackButton } from './admin-page.styles'

import { t } from '../../utils/helpers.utils'
import { SwitchContainer } from '../left-menu/index.styles'
import { Text } from '../text.component'
import { languageOptions } from '../left-menu/header.component'

const Header = () => {
  const navigate = useNavigate();
  const { i18n: { language, changeLanguage } } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const latinicPattern = /[A-Za-z]/;
    if (latinicPattern.test(inputValue)) {
      return;
    }
    setSearchText(e.target.value);
    setSearchParams({ searchText: e.target.value });
  }

  const handleGoBack = () => {
    if (window.location.href.includes('searchText')) {
      setSearchParams({ searchText: '' });
      setSearchText('');
    } else if (!isNaN(+window.location.pathname.split('/').pop()!)) {
      const location = window.location.pathname.split('/');
      if (location[location.length - 2] === 'admin') {
        navigate('/admin/snp')
      } else {
        navigate(window.location.pathname.split('/').slice(0, -1).join('/'))
      }
    } else {
      navigate(-1)
    }
  }

  const handleLanguageChange = (val: 'ru' | 'kz') => {
    changeLanguage(val)
    localStorage.setItem('lng', val);
  }

  useEffect(() => {
    const lang = localStorage.getItem('lng');
    if (lang) {
      changeLanguage(lang);
    } else {
      changeLanguage('ru');
    }
  }, [localStorage.getItem('lng')])

  useEffect(() => {
    if (searchParams.get('searchText') && searchParams.get('searchText') !== searchText) {
      setSearchText(searchParams.get('searchText')!);
    }
  }, [searchParams])

  return (
    <HeaderWrapper>
      <SwitchContainer>
        {languageOptions.map((option) => (
          <Text fontSize='0.75rem' color={language === option.value ? 'var(--primary-color)' : '#818689'} onClick={() => handleLanguageChange(option.value)}>
            {option.label}
          </Text>
        ))}
      </SwitchContainer>
      <BackButton onClick={handleGoBack}>
        <ArrowLeft />
        {t('back', language)}
      </BackButton>
      <CustomSelect options={[{ value: 'Kostanay', label: t('aktobeRegion', language) }]} />
      {!window.location.href.includes('analytics') && <Search searchValue={searchText} onChange={handleChange} />}
    </HeaderWrapper>
  )
}

export default Header