import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useMap, Map } from '@vis.gl/react-google-maps';
import styled from "styled-components";
import { IMarker, IRegion } from "../../interfaces/snp.interface";
import { getLang } from '../../utils/helpers.utils';
import ThemeContext from '../../contexts/theme.context';

const MapWrapper = styled.div`
  width: calc(100vw);
  height: calc(100vh);
  overflow: hidden;
`;

const MapSearch = styled.input`
  width: 15rem;
  height: 2rem;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid rgba(0, 0, 0, 0.50);
  outline: none;
  margin: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.50);
`;

const MapPlaceControlsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 10px;
`;

const MapPlaceControlButton = styled.button`
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background-color: white;
  font-weight: 600;
  box-shadow: 0 1px 3px rgba(60,64,67,0.3),
              0 4px 8px 3px rgba(60,64,67,0.15);
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.50);
  }
`;

type Props = {
  setSelectedRegion: Dispatch<SetStateAction<IRegion | undefined>>;
  setSelectedMarker: Dispatch<SetStateAction<IMarker | null>>;
}

const GoogleMapComponent: React.FC<Props> = ({ setSelectedRegion, setSelectedMarker }) => {
  const map = useMap();

  const { theme } = useContext(ThemeContext);

  const styles: Record<string, google.maps.MapTypeStyle[]> = {
    default: [],
    hide: [
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.neighborhood",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "off" }],
      },
    ],
    dark: [
      { elementType: 'geometry', stylers: [{ color: '#212121' }] },
      { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#212121' }] },
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [{ color: '#757575' }]
      },
      {
        featureType: 'administrative.country',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }]
      },
      {
        featureType: 'administrative.land_parcel',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#bdbdbd' }]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#181818' }]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#1b1b1b' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [{ color: '#2c2c2c' }]
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#8a8a8a' }]
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#373737' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#3c3c3c' }]
      },
      {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [{ color: '#4e4e4e' }]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }]
      },
      {
        featureType: 'transit',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#000000' }]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#3d3d3d' }]
      }
    ],

  };

  const extractKato = (str: string) => {
    const match = str.match(/Като:\s*(\d+)/);
    return match ? +match[1] : null;
  };


  useEffect(() => {
    if (map) {
      // const url = `${window.location.origin}/api/public/map/road`
      const url = 'https://daniyaralmukhan.github.io/kml/styled-3.kml';

      const roadworksLayer = new google.maps.KmlLayer({
        url,
        suppressInfoWindows: true,
        preserveViewport: true,
        zIndex: 300,
      });


      roadworksLayer.setMap(map);

      let currentInfoWindow: google.maps.InfoWindow | null = null;

      const zoomChangeListener = google.maps.event.addListener(map, 'zoom_changed', () => {
        const zoomLevel = map.getZoom();
        if (zoomLevel && zoomLevel >= 12) {
        } else if (zoomLevel && zoomLevel < 8) {
          roadworksLayer?.setMap(null);
        } else {
          roadworksLayer?.setMap(map);
        }
        roadworksLayer?.setMap(map);

      });

      google.maps.event?.addListener(roadworksLayer, 'click', (event: google.maps.KmlMouseEvent) => {
        console.log(event)
        if (event.featureData) {
          const { latLng, featureData } = event;

          const content = `<div>
            ${featureData.infoWindowHtml}

            <div id="commentsSection" style="padding: 10px; background-color: #f9f9f9; border-radius: 8px; border: 1px solid #ccc; margin-top: 10px;">
              <div style="display: flex;">
                <span style="color: #118f84; font-weight: bold;">Random User:</span>
                <span style="color: #202224; margin-left: 5px;">This is a random comment!</span>
              </div>
              <div style="display: flex; margin-top: 10px;">
                <span style="color: #118f84; font-weight: bold;">Random User 2:</span>
                <span style="color: #202224; margin-left: 5px;">This is a random comment 2!</span>
              </div>
            </div>

             <div style="display: flex; align-items: center; margin-top: 5px;">
               <span>+7</span>
               <input id="phoneInput" type="text" placeholder="XXXXXXXXXX" maxlength="10" style="margin-left: 5px;" />
             </div>

             <div style="width: 100%;  margin-top: 5px;">
               <textarea style="width: 100%" id="messageInput" rows="2" placeholder="Your message"></textarea>
             </div>

             <button id="submitButton">Отправить</button> 
          </div>`

          // const content = featureData.infoWindowHtml;

          if (currentInfoWindow) {
            currentInfoWindow.close();
          }

          const infoWindow = new google.maps.InfoWindow({
            content: content,
            position: latLng,
          });

          infoWindow.open(map);

          currentInfoWindow = infoWindow;

          google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
            const phoneInput = document.getElementById('phoneInput') as HTMLInputElement;
            const messageInput = document.getElementById('messageInput') as HTMLTextAreaElement;
            const submitButton = document.getElementById('submitButton');
            const commentsSection = document.getElementById('commentsSection');

            phoneInput.addEventListener('input', () => {
              const inputVal = phoneInput.value;
              phoneInput.value = inputVal.replace(/[^0-9]/g, '');
              if (inputVal.length > 10) {
                phoneInput.value = inputVal.slice(0, 10);
              }
            });

            if (submitButton && commentsSection) {
              submitButton.addEventListener('click', () => {
                const phoneNumber = phoneInput.value;
                const message = messageInput.value;

                if (phoneNumber.length !== 10) {
                  alert("Please enter a valid phone number in the form of 7XXXXXXXXXX");
                  return;
                }

                const obj = {
                  phoneNumber: `+7${phoneNumber}`,
                  message,
                  featureDataId: featureData.id,
                  name: featureData.name,
                }

                addComment(commentsSection, obj.phoneNumber, obj.message);

                console.log(obj)
                phoneInput.value = '';
                messageInput.value = '';


              });
            }
          });
        }
      });

      function addComment(commentsSection: HTMLElement, name: string, message: string) {
        const commentDiv = document.createElement('div');
        commentDiv.style.display = 'flex';
        commentDiv.style.marginTop = '10px';
      
        const nameSpan = document.createElement('span');
        nameSpan.style.color = '#118f84';
        nameSpan.style.fontWeight = 'bold';
        nameSpan.innerText = `${name}:`;
      
        const messageSpan = document.createElement('span');
        messageSpan.style.color = '#202224';
        messageSpan.style.marginLeft = '5px';
        messageSpan.innerText = message;
      
        commentDiv.appendChild(nameSpan);
        commentDiv.appendChild(messageSpan);
        commentsSection.appendChild(commentDiv);
      }
      

      const input = document.getElementById('pac-input') as HTMLInputElement;

      input.addEventListener('input', () => {
        if (input.value === '') {
          clearMarkers();
          updateMapStyles(false);
        }
      });

      const searchBox = new google.maps.places.SearchBox(input);

      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(input);

      let markers: google.maps.Marker[] = [];
      let infoWindow: google.maps.InfoWindow | null = null;

      const clearMarkers = () => {
        markers.forEach(marker => marker.setMap(null));
        markers = [];
        if (infoWindow) {
          infoWindow.close();
        }
      };

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();

        if (!places || places.length === 0) {
          clearMarkers();
          updateMapStyles(false);
          return;
        }

        clearMarkers();

        const newMarkers: google.maps.Marker[] = [];

        const bounds = new google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            return;
          }

          const marker = new google.maps.Marker({
            map,
            title: place.name,
            position: place.geometry.location,
          });

          marker.addListener('click', () => {
            if (infoWindow) {
              infoWindow.close();
            }
            const newInfoWindow = new google.maps.InfoWindow({
              content: `<div><h4>${place.name}</h4></div>`,
            });
            newInfoWindow.open(map, marker);
            infoWindow = newInfoWindow;
          });

          newMarkers.push(marker);

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        markers = newMarkers;
        map.fitBounds(bounds);

        updateMapStyles(newMarkers.length > 0);
      });

      map.addListener('click', (event: google.maps.IconMouseEvent) => {
        if (!event.placeId) {
          map.setZoom(7);
        }
      });

      const placesService = new google.maps.places.PlacesService(map);

      const searchPlaces = (type: string) => {
        const request = {
          location: map.getCenter(),
          radius: 5000,
          type,
        };

        placesService.nearbySearch(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && results) {
            clearMarkers();

            const newMarkers: google.maps.Marker[] = [];

            results.forEach((place) => {
              const marker = new google.maps.Marker({
                map,
                position: place.geometry?.location,
                title: place.name,
              });

              marker.addListener('click', () => {
                if (infoWindow) {
                  infoWindow.close();
                }
                const newInfoWindow = new google.maps.InfoWindow({
                  content: `<div><h4>${place.name}</h4></div>`,
                });
                newInfoWindow.open(map, marker);
                infoWindow = newInfoWindow;
              });

              newMarkers.push(marker);
            });

            markers = newMarkers;
            updateMapStyles(newMarkers.length > 0);
          }
        });
      };

      const centerControlDiv = document.getElementById('controls');
      if (centerControlDiv) {
        Array.from(centerControlDiv.children).forEach((item) => {
          item.addEventListener('click', () => {
            const name = (item as HTMLButtonElement).name;
            if (name === 'clear') {
              clearMarkers();
              updateMapStyles(false);
            } else if (name === 'roads') {
              roadworksLayer.setMap(roadworksLayer.getMap() ? null : map);
            } else {
              searchPlaces(name);
            }
          });
        });
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
      }

      const updateMapStyles = (hasMarkers: boolean) => {
        if (hasMarkers) {
          map.setOptions({ styles: styles['dark'] });
        } else {
          map.setOptions({ styles: styles['dark'] });
        }
      };

      map.addListener('bounds_changed', () => {
        searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });

      return () => {
        google.maps.event.removeListener(zoomChangeListener);
      };
    }
  }, [map]);

  return (
    <MapWrapper>
      <MapSearch type={'search'} id={'pac-input'} placeholder={'Поиск в Google картах'} />
      <Map
        defaultZoom={6}
        fullscreenControl={false}
        streetViewControl={false}
        defaultCenter={{ lat: 51.162391, lng: 67.391048 }}
      />
      <MapPlaceControlsContainer id={'controls'}>
        {/* <MapPlaceControlButton name={'pharmacy'}>Аптеки</MapPlaceControlButton>
        <MapPlaceControlButton name={'groceries'}>Продукты</MapPlaceControlButton>
        <MapPlaceControlButton name={'hospital'}>Больницы</MapPlaceControlButton>
        <MapPlaceControlButton name={'clear'}>Очистить</MapPlaceControlButton> */}
        <MapPlaceControlButton name={'roads'}>Дороги</MapPlaceControlButton>
      </MapPlaceControlsContainer>
    </MapWrapper>
  );
};

export default GoogleMapComponent;