import Icon1 from '../assets/icons/navlinks/navlink1.svg'
import Icon2 from '../assets/icons/navlinks/navlink2.svg'
import Icon3 from '../assets/icons/navlinks/navlink3.svg'
import Icon4 from '../assets/icons/navlinks/navlink4.svg'

export const navLinks: { [key: string]: string }[] = [
  {
    name: 'infrastructure-projects',
    icon: Icon1,
  },
  // {
  //   name: 'agricultural_producers',
  //   icon: Icon2,
  // },
  {
    name: 'snp_improvement',
    icon: Icon3,
  },
  {
    name: 'snp_investment',
    icon: Icon4,
  },
]

export const reportTypes: string[] = [
  'education',
  'health',
  'culture-sport',
  'water-supply',
  // 'gas-supply',
  'internet-communication',
  'access-road',
  'village-road',
  'lighting',
]

export const tabLinks = [
  'education',
  'health',
  'culture',
  'water',
  // 'gas',
  'internet',
  'driveway',
  'countryroad',
  'light',
]

export const sections: string[] = [
  'snp',
  // 'analytics',
  // 'sections',
]

export const tabs: string[] = [
  'general_info',
  'roads-condition',
  'self-sufficiency',
  'ser',
  'infrastructure',
  'population-dynamic',
  'tax',
  'budget',
  'population-demand',
  'infrastructure-projects',
  'snp_improvement',
  'snp_investment',
  // 'agricultural_producers',

  // 'additional_info'
]

export const headerOptions = ['snp', 'finance-aid', 'snp-info', 'TEST-IFRAME'];
// export const headerOptions = ['snp', 'analytics', 'finance-aid', 'wage-fund', 'archive-digitalization', 'weekly-income', 'livestock',];

export const controlOptions = [
  // 'finance-aid',
  'wage-fund',
  'weekly-income',
  'livestock',
  'entrepreneurship',
  'archive-digitalization',
  'tourism',
  'architecture',
  'education',
  'PE',
  'energy',
  'natural-resources',
  'coordination'
]