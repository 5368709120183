import React, { useEffect } from 'react'
import { Footer, FooterButton } from '../admin-page/admin-page.styles'
import { Save, XIcon, GoUp, Trash } from '../../assets/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteObjectWithType } from '../../requests/supervisor.request';
import Modal from '../modal-component';

interface IProps {
  type: string;
  formId: number;
};

const FormFooter: React.FC<IProps> = ({ type, formId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [link, setLink] = React.useState('infrastructure-projects');
  const { kato } = useParams<any>();


  const handleCancel = () => {
    navigate(`/admin/${kato}/${link}`)
  }

  const handleDelete = () => {
    setIsOpen(true)
  }

  const confirmDelete = () => {
    deleteObjectWithType(type, formId).then(_ => {
      navigate(`/admin/${kato}/${link}`)
    })
  }

  useEffect(() => {
    setLink(type === 'SNP_AGRICULTURE_PRODUCERS' ? 'snp_agriculture_producers' : type === 'SNP_INVESTMENT' ? 'snp_investment' : 'infrastructure-projects')
  }, [type])

  return (
    <Footer>
      <div className="buttons">
        <FooterButton type='submit' variant="save"><Save /> {t('save')}</FooterButton>
        <FooterButton variant="cancel" type="button" onClick={handleCancel}><XIcon width={20} /> {t('cancel')}</FooterButton>
      </div>
      <div className="buttons">
        <FooterButton variant="delete" type="button" onClick={handleDelete}><Trash width={16} /> {t('delete-section')}</FooterButton>
        <FooterButton variant="go-up" type="button"><GoUp /> {t('go-up')}</FooterButton>
      </div>

      <Modal isFileUpload isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <h1>{t('confirm-delete-section')}</h1>
        <div className="buttons">
          <FooterButton variant="delete" type='button' onClick={confirmDelete}><Trash width={16} /> {t('form.requirementsObj.YES')}</FooterButton>
          <FooterButton variant="cancel" type='button' onClick={() => setIsOpen(false)}> {t('form.requirementsObj.NO')}</FooterButton>
        </div>
      </Modal>
    </Footer>
  )
}

export default FormFooter