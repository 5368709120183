import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '../modal-component';

import {
  AddField,
  DeleteX,
  EditPen,
  Save,
  GoUp,
  Trash,
} from '../../assets/icons';

import {
  Wrapper,
  FormBlock,
  FormGroup,
  Footer,
  FooterButton
} from './admin-page.styles';

import {
  IGeneralInfo,
  IProject,
  OfficialRoleType,
} from '../../interfaces/snp.interface';

import { getRegionList, getSettlementTree } from '../../requests/kato.request';
import { getGeneralInfo } from '../../requests/snp.request';
import {
  deleteImage,
  uploadGeneralImage,
  changeStatus,
  deleteAll,
  updateFormPartial,

} from '../../requests/supervisor.request';

import { capitalize, checkVal, getLang, handleGoUp, isRegion, keepCapitalized, OBLAST_KATO, t } from '../../utils/helpers.utils'
import ImageGrid from '../insfrastructureProjects/image-grid.component';
import { Text } from '../text.component';

const initialFormData: any = {
};

const errMsg: any = {
  akimNameKz: 'fullName',
  akimNameRu: 'fullName',
  akimPositionKz: 'position',
  akimPositionRu: 'position',
  akimImageId: 'photo',
  akimPhone: 'phoneS'
}

export const getImageUrl = (imageId: number) => {
  return `/api/public/image/load?imageId=${imageId}`;
}

const GeneralInfo = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [regions, setRegions] = useState<any[]>([]);
  const [snps, setSnps] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedRole, setSelectedRole] = useState<OfficialRoleType | null>(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [formData, setFormData] = useState<any>(initialFormData);
  const [isFinished, setIsFinished] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    setSnps(regions.find((item) => +item.value === +e.target.value)?.children || []);

    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const validateFormBySections = (values: IProject) => {
    setErrors({});
    // SECTION ONE 
    const sectionOneKeys = ['date', 'population', 'populationDynamic', 'avgSalary', 'area'];

    if (sectionOneKeys.some(key => checkVal(values[key]))) {
      for (const key of sectionOneKeys) {
        if (!checkVal(values[key])) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.${key}`, language));
          return false;
        }
      }
    }

    // SECTION TWO

    const sectionTwoKeys = ['akimNameKz', 'akimNameRu', 'akimPositionKz', 'akimPositionRu', 'akimInPositionFrom', 'akimPhone'];
    // const sectionTwoKeys = Object.keys(values.akim).filter(item => item !== 'imageId');
    if (sectionTwoKeys.some(key => checkVal(values.generalInfo[key]))) {
      for (const key of sectionTwoKeys) {
        if (!checkVal(values.generalInfo[key])) {
          const foundKey = errMsg[key] || key
          setErrors((prev: any) => ({ ...prev, [foundKey]: true }))
          toast.error(t(`errors.${foundKey}`, language));
          return false;
        }
      }
    }

    return true;
  };

  const handleSubmitForm = (values: IProject) => {
    setErrors({});

    const valuesToSubmit: any = {
      generalInfo: {
        ...values.generalInfo,
        akimNameKz: capitalize(values.generalInfo.akimNameKz) || null,
        akimNameRu: capitalize(values.generalInfo.akimNameRu) || null,
        akimPositionKz: keepCapitalized(values.generalInfo.akimPositionKz) || null,
        akimPositionRu: keepCapitalized(values.generalInfo.akimPositionRu) || null,
        akimImageId: formData.akimImageId || values.generalInfo.akimImageId || null,
      },
      childInfoList: values.childInfoList,
    }

    if (kato && validateFormBySections(valuesToSubmit)) {
      updateFormPartial(kato, valuesToSubmit)
        .then(() => {
          toast.success(t('toast.save_success'))
          loadForm();
        })
        .catch(() => toast.error('Ошибка при сохранении'));
    }
  }

  const handleSelectRole = (role: OfficialRoleType) => {
    setSelectedRole(role);
    openPhotoModal()
  }

  const handleImageUpload = async () => {
    if (!selectedImage) {
      return;
    }

    kato && selectedRole && uploadGeneralImage(+kato, selectedRole, selectedImage)
      .then(_ => {
        loadForm();
      })

    closePhotoModal();
  };

  const handleDeleteImage = (imageId: number) => {
    deleteImage(imageId).then(_ => loadForm())
  };

  const handleImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setSelectedImageName(file.name || '');
      setSelectedImage(file);
    }
  };

  const handleDelete = () => {
    setIsConfirmOpen(true);
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (validateFormBySections(formikRef.current.values)) {
        setIsFinished(true);
        changeStatus(snp, 'COMPLETED').then(res => {
          formikRef.current.setValues({ ...formikRef.current.values, status: 'COMPLETED' })
          handleSubmitForm({ ...formikRef.current.values, status: 'COMPLETED' })
        })
      }
    } else {
      setIsFinished(false);
      changeStatus(snp, 'IN_PROCESS').then(res => {
        formikRef.current.setValues({ ...formikRef.current.values, status: 'IN_PROCESS' })
        handleSubmitForm({ ...formikRef.current.values, status: 'IN_PROCESS' })
      })
    }
  }

  const openPhotoModal = () => {
    setIsOpen(true);
  };

  const closePhotoModal = () => {
    setIsOpen(false);
  };

  const confirmDelete = () => {
    deleteAll(snp).then(() => navigate('/admin/snp'))
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res: any) => {
      const data = {
        ...res,
        generalInfo: {
          ...res.generalInfo,
          date: res.generalInfo?.date ? res.generalInfo.date.split('T')[0] : '',
          akimInPositionFrom: res.generalInfo?.akimInPositionFrom ? res.generalInfo.akimInPositionFrom.split('T')[0] : ''
        },
      }

      const status = res.status;
      setIsFinished(status === 'COMPLETED');

      setFormData(data as IGeneralInfo);

      setIsLoading(false);
    });
  }, [region, snp, kato])

  useEffect(() => {
    if (katoAccessList?.length && kato) {
      if (!katoAccessList.includes(+kato) && !katoAccessList.includes(0) && !katoAccessList.some(item => kato.toString().startsWith(item.toString().substring(0, 4)))) {
        navigate('/admin/snp')
      }
      const snpItem: { kato: number, ppKato: number } = JSON.parse(localStorage.getItem('snp') as string);
      const val = kato ? kato : snpItem.kato;
      getSettlementTree().then((res: any) => {
        getRegionList().then((regionRes: any) => {
          const withChildren = (Object.keys(res.data)
            .map((item: any) => ({
              key: item, children: Object.keys(res.data[item])
                .map((key: string) => {
                  const { pnameKz, pnameRu, pkato } = res.data[item][key][0]
                  return ({ value: pkato, label: pnameRu, labelKz: pnameKz })
                })
            })))

          const regions = withChildren.map((elem: any) => ({ ...elem, ...regionRes.find((item: any) => [item.nameRu, item.nameKz].includes(elem.key)) }))
          const filtered = katoAccessList.includes(0)
            ? regions
              .map((item: any) => ({ value: item.kato, label: item.nameRu, labelKz: item.nameKz, ...item }))
            : regions
              .map((item: any) => ({ value: item.kato, label: item.nameRu, labelKz: item.nameKz, ...item }))
              .filter((item) => katoAccessList.some(kato => kato.toString().startsWith(item.kato.toString().substring(0, 4))))

          setRegions(filtered)

          const filteredSnps = katoAccessList.includes(0)
            ? filtered[0].children
            : filtered[0].children.filter((item: any) => katoAccessList.includes(+item.kato));

          setSnps(filteredSnps);

          if (val) {
            const found = regions.find((item: any) => item.children.find((item: any) => +item.value === +val)) || regions.find((item: any) => +item.kato === +val);
            if (found) {
              setRegion(+found.value || +found.kato);

              const filteredSnps = katoAccessList.includes(0)
                ? found.children
                : found.children.filter((item: any) => katoAccessList.includes(+item.value) || katoAccessList.some(item => kato.toString().startsWith(item.toString().substring(0, 4))));
              setSnps(filteredSnps);
              setSnp(+val);
            }
          }
        })
      });
    }
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {regions.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.district.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {snps.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const onlyLetters = (value: string) => value.replace(/[^\p{L}\s]/gu, '');

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: any) => {
    return (
      <div className="grid-item">
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="generalInfo.date">{t(`aktobe.date`, lang)}</label>
            <Field className={`${errors[`date`] ? 'error' : ''}`} name="generalInfo.date" as="input" type="date" />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="generalInfo.population">{t(`aktobe.population`, lang)}</label>
            <Field className={`${errors[`population`] ? 'error' : ''}`} name="generalInfo.population" as="input" type="number" />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="generalInfo.populationDynamic">{t(`aktobe.population-dynamic`, lang)}</label>
            <Field
              className={`${errors[`populationDynamic`] ? 'error' : ''}`}
              name="generalInfo.populationDynamic"
              as="input"
              type="number"
            />
          </div>
        </FormGroup>

        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="generalInfo.avgSalary">{t(`aktobe.avgSalary`, lang)}, ₸</label>
            <Field className={`${errors[`avgSalary`] ? 'error' : ''}`} name="generalInfo.avgSalary" as="input" type="number" />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="generalInfo.area">{t(`aktobe.area`, lang)}, га</label>
            <Field className={`${errors[`area`] ? 'error' : ''}`} name="generalInfo.area" as="input" type="number" />
          </div>
        </FormGroup>

        <FormBlock type='white'>
          <div className="child-grid" style={{ gridTemplateColumns: `repeat(${isRegion(formData.kato) ? '2' : '3'}, 1fr)` }}>
            <Text fontSize='0.75rem'>{t('aktobe.name')}</Text>
            {!isRegion(formData.kato) && <Text fontSize='0.75rem' ta="right">{isRegion(formData.kato) ? t('aktobe.toOblCenter') : t('aktobe.toCenter')}</Text>}
            <Text fontSize='0.75rem' ta={!isRegion(formData.kato) ? 'right' : 'left'}>{t('aktobe.population')}</Text>

            {
              formData.childInfoList.map((item: any, index: number) =>
                <React.Fragment key={index}>
                  <Text>{item[`name${lang}`]}</Text>
                  {!isRegion(formData.kato) && <FormGroup>
                    <Field
                      name={`childInfoList.${index}.distance`}
                    />
                  </FormGroup>}
                  <FormGroup>
                    <Field
                      name={`childInfoList.${index}.population`}
                    />
                  </FormGroup>
                </React.Fragment>
              )
            }
          </div>
        </FormBlock>

        <FormBlock type='gray' >
          <div className="title">{t(`aktobe.akim-title`, lang)}</div>
          <FormGroup>
            <label
              htmlFor={`generalInfo.akimName${lang}`}
              className={'required'}
            >
              {t(`aktobe.akim.fullName`, lang)}
            </label>
            <Field
              name={`generalInfo.akimName${lang}`}
              type="text"
              className={`capitalize ${errors[`fullName`] ? 'error' : ''}`}
              onChange={(e: any) => {
                const val = onlyLetters(e.target.value);
                setFieldValue(`generalInfo.akimName${lang}`, capitalize(val))
              }}
            />
          </FormGroup>
          <FormGroup>
            <label
              htmlFor={`generalInfo.akimPosition${lang}`}
              className={'required'}
            >
              {t(`aktobe.akim.position`, lang)}
            </label>
            <Field
              name={`generalInfo.akimPosition${lang}`}
              type="text"
              onChange={(e: any) => {
                const val = onlyLetters(e.target.value);
                setFieldValue(`generalInfo.akimPosition${lang}`, keepCapitalized(val))
              }}
              className={`${errors[`position`] ? 'error' : ''}`}
            />
          </FormGroup>
          <div className="row">
            <FormGroup>
              <label
                htmlFor={`generalInfo.akimInPositionFrom`}
                className={'required'}
              >
                {t(`aktobe.akim.inPositionFrom`, lang)}
              </label>
              <Field
                name={`generalInfo.akimInPositionFrom`}
                type="date"
                as='input'
                className={`${errors[`inPositionFrom`] ? 'error' : ''}`}
              />
            </FormGroup>
            <FormGroup>
              <label
                htmlFor={`generalInfo.akimPhone`}
                className={'required'}
              >
                {t(`aktobe.akim.phoneNumber`, lang)}
              </label>
              <Field
                name={`generalInfo.akimPhone`}
                as="input"
                type="number"
                className={`${errors[`phone`] ? 'error' : ''}`}
              />
            </FormGroup>
          </div>

          {
            lang.toLowerCase() === language && <FormGroup>
              <div className="photo">
                <label className={'required'} htmlFor={`akim.photoId`}>{t(`aktobe.akim.photoId`, lang)}</label>
                <div className={`image ${errors[`photoId`] ? 'error' : ''} `}>
                  {formData.generalInfo.akimImageId
                    ? <>
                      <img src={getImageUrl(formData.generalInfo.akimImageId)} alt="person" />
                      <div className="buttons">
                        <div className='edit' onClick={() => handleSelectRole('SUPERVISOR')}><EditPen /> {t('form.photo.edit', language)}</div>
                        <div className='delete' onClick={() => handleDeleteImage(+formData.generalInfo.akimImageId)} ><DeleteX /> {t('form.photo.delete', language)}</div>
                      </div>
                    </>
                    : <div className="buttons">
                      <div className='upload' onClick={() => handleSelectRole('SUPERVISOR')}> <AddField /> {t('form.photo.upload', language)}</div>
                    </div>}
                </div>
              </div>
            </FormGroup>
          }
        </FormBlock>
      </div >
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={formData}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {kato && +kato !== OBLAST_KATO && renderSelects(getLang())}
                  {kato && +kato !== OBLAST_KATO && renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                      <div className='checkbox'>

                        <input type="checkbox" name="is-finished" id="is-finished" checked={isFinished} onChange={handleCheckboxChange} />
                        {kato && +kato !== OBLAST_KATO ? <label htmlFor="is-finished">{t(`${kato && isRegion(+kato) ? 'region' : 'so'}-is-finished`, language)}</label>
                          : <label htmlFor="is-finished">Область заполнена полностью</label>}
                      </div>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="delete" type='button' onClick={handleDelete}><Trash /> {t('delete-all', language)}</FooterButton>
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>

            <ImageGrid
              formData={formData}
              lang={language}
              type='general'
              images={formData.imageIds || []}
              loadForm={loadForm}
            />
          </Wrapper>

          <Modal isFileUpload isOpen={isOpen} onClose={closePhotoModal}>
            <label className="input-file">
              <span className="input-file-text">{selectedImageName}</span>
              <input type="file"
                accept="image/*"
                id="imageInput"
                onChange={handleImageSelect} />
              <span className="input-file-btn">{t('choose-file', language)}</span>
            </label>
            <button className="upload-button" onClick={handleImageUpload}>
              {t('form.photo.upload', language)}
            </button>
          </Modal>
          <Modal isFileUpload isOpen={isConfirmOpen} onClose={() => setIsConfirmOpen(false)}>
            <h1>{t('confirm-delete-village', language)}</h1>
            <div className="buttons">
              <FooterButton variant="delete" type='button' onClick={confirmDelete}><Trash width={16} /> {t('form.requirementsObj.YES', language)}</FooterButton>
              <FooterButton variant="cancel" type='button' onClick={() => setIsConfirmOpen(false)}> {t('form.requirementsObj.NO', language)}</FooterButton>
            </div>
          </Modal>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default GeneralInfo