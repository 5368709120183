import React, { FC, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text } from '../../text.component';
import { getImageUrl } from '../education.component';
import { formatDate, handleDownload } from '../../../utils/helpers.utils';
import { DownloadIcon, Lupa, SwipeLeft, SwipeRight } from '../../../assets/icons';

const ImageGridContainer = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ImageGridSwiper = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: hidden;
  gap: 1.25rem;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const ArrowButton = styled.button<{ position: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  ${({ position }) => position}: 0.625rem;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  img {
    max-height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #DBDCE0;
  }

  .img {
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      display: none;
      cursor: pointer;
      justify-content: center;
      align-items: center;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    &:hover .overlay {
      display: flex;
    }
  }

  .bot {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background: #F6F6F6;
      border: none;
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

interface IProps {
  images: any[];
  openImageModal: (image: any, images: any, name: string) => void;
  name: string;
}

const ImageGrid: FC<IProps> = ({ images, openImageModal, name }) => {
  const { t } = useTranslation();
  const [imagesObj, setImagesObj] = useState<any>({});
  const swiperRef = useRef<HTMLDivElement>(null);

  const scrollByOne = (direction: 'left' | 'right') => {
    const scrollAmount = 150; // Adjust this value based on the image width
    if (swiperRef.current) {
      swiperRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const keySet = Array.from(new Set(images.map((item: any) => item.fileType)));
    const newObj = keySet.reduce((result: any, key: string) => {
      const filteredImages = images.filter((item: any) => item.fileType === key);
      result[key] = filteredImages;
      return result;
    }, {});

    setImagesObj(newObj);
  }, [images]);

  return (
    imagesObj && !!Object.keys(imagesObj).length && (
      <ImageGridContainer>
        {Object.keys(imagesObj).map((key: string) => (
          <>
            <Text color="#118F84" fontSize="0.875rem" fontWeight={500}>
              {t(key)} ({imagesObj[key].length} фото)
            </Text>
            <Wrapper>
              <ArrowButton position="left" onClick={() => scrollByOne('left')}><SwipeLeft /> </ArrowButton>
              <ImageGridSwiper ref={swiperRef}>
                {imagesObj[key].map((image: any) => (
                  <>
                    {image.imageIds.map((id: number) => (
                      <Image>
                        <div className="img">
                          <img src={getImageUrl(id)} alt="" />
                          <div className="overlay" onClick={() => openImageModal(id, images, name)}>
                            <div>
                              <Lupa />
                            </div>
                          </div>
                        </div>
                        <div className="bot">
                          <Text color="#818689" fontSize="0.75rem" fontWeight={400}>
                            {formatDate(image.date)}
                          </Text>
                          <button onClick={() => handleDownload(getImageUrl(id))}>
                            <DownloadIcon />
                          </button>
                        </div>
                      </Image>
                    ))}
                  </>
                ))}
              </ImageGridSwiper>
              <ArrowButton position="right" onClick={() => scrollByOne('right')}> <SwipeRight /></ArrowButton>
            </Wrapper>
          </>
        ))}
      </ImageGridContainer>
    )
  );
};

export default ImageGrid;
