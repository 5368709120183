import React, { ChangeEvent, useEffect, useState } from 'react'
import { Field } from 'formik'
import { AddField } from '../../assets/icons';
import { FooterButton, FormGroup } from '../admin-page/admin-page.styles'
import { FormBlock, FormBlockButton } from '../insfrastructureProjects/infrastructure.styles'
import { documentationStatusOptions } from '../../constants/snp.constant';
import { lowerAndTrim, t } from '../../utils/helpers.utils';
import { ITempFund } from '../insfrastructureProjects/education-form.component';

interface IProps {
  formData: any;
  setFormData: (formData: any, ...rest: any) => void;
  setFieldValue: (field: string, value: any) => void;
  lang: 'Ru' | 'Kz';
  type: string;
  errors?: any,
  tempFund: ITempFund,
  setTempFund: (data: any) => void,
  onAdd: any,
  onDelete: any,
}

const DocumentationForm: React.FC<IProps> = (props) => {
  const { formData, setFormData, setFieldValue, lang, onDelete, onAdd, errors, tempFund, setTempFund, type } = props
  const [dates, setDates] = useState<any>({
    workStart: null,
    workEnd: null
  });

  const handleCancelAdd = () => {
    setTempFund({ labelRu: '', labelKz: '', value: '', isAdding: false, labelError: false, valueError: false })
  }

  const handleValueChange = (key: string, val: any, setFieldValue: (fieldName: string, value: string) => void) => {
    if (key === 'workStart' || key === 'workEnd') {
      if (val.split('-')[0].length > 4) return;
      setDates((prev: any) => ({ ...prev, [key]: val }))
      setFieldValue(key, val);
      setFormData((prev: any) => ({ ...prev, [key]: val }));
    } else {
      setFieldValue(key, val);
      setFormData({ ...formData, [key]: val });
    }
  }

  return (
    <>
      <FormBlock>
        <div className="row sb wrap">
          <FormGroup>
            <label htmlFor={`documentationStatus`}>{t(`form.documentationStatus`, lang)}</label>
            <Field
              id={`documentationStatus`}
              name={`documentationStatus`}
              as={'select'}
              className={errors['documentationStatus'] ? 'error' : ''}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => handleValueChange('documentationStatus', e.target.value, setFieldValue)}
              value={formData.documentationStatus}
            >
              <option value={""} selected hidden></option>
              {documentationStatusOptions.map((option) => <option value={option.value} key={option.value}>{t(`form.documentationStatusObj.${option.value}`, lang)}</option>)}
            </Field>
          </FormGroup>
        </div>

        {
          formData.documentationStatus === 'HAVE' && <>
            <div className="row sb wrap">
              <FormGroup>
                <label htmlFor={`workStart`}>{t(`form.workStart`, lang)}</label>
                <Field
                  id={`workStart`}
                  name={`workStart`}
                  placeholder={t(`form.workStart`, lang)}
                  as="input"
                  type="date"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('workStart', e.target.value, setFieldValue)}
                  max={dates.workEnd || formData.workEnd?.split('T')[0] || null}
                  className={errors['date'] ? 'error' : ''}
                  defaultValue={formData.workStart?.split('T')[0] || null}
                  value={dates.workStart || formData.workStart?.split('T')[0] || null}
                />
              </FormGroup>
            </div>
            <div className="row sb wrap">
              <FormGroup>
                <label htmlFor={`workEnd`}>{t(`form.workEnd`, lang)}</label>
                <Field
                  id={`workEnd`}
                  name={`workEnd`}
                  placeholder={t(`form.workEnd`, lang)}
                  as="input"
                  type="date"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('workEnd', e.target.value, setFieldValue)}
                  max={'2030-12-31'}
                  className={errors['date'] ? 'error' : ''}
                  defaultValue={formData.workEnd?.split('T')[0] || null}
                  value={dates.workEnd || formData.workEnd?.split('T')[0] || null}
                  min={dates.workStart || formData.workStart?.split('T')[0] || null}
                />
              </FormGroup>
            </div>
            <div className="row sb wrap">
              <FormGroup>
                <label htmlFor={`requiredFundSource${lang}`}>{t(`form.requiredFundSource`, lang)}</label>
                <Field
                  id={`requiredFundSource${lang}`}
                  name={`requiredFundSource${lang}`}
                  placeholder={t(`form.requiredFundSource`, lang)}
                  onChange={(e: any) => handleValueChange(`requiredFundSource${lang}`, lowerAndTrim(e.target.value), setFieldValue)}
                  className={errors['requiredFundSource'] ? 'error' : ''}
                  value={formData[`requiredFundSource${lang}`]}
                />
              </FormGroup>
            </div>
            <div className="row sb wrap">
              <FormGroup>
                <label htmlFor={`requiredFundAmount`}>{t(`form.requiredFundAmount`, lang)}</label>
                <Field
                  id={`requiredFundAmount`}
                  name={`requiredFundAmount`}
                  placeholder={t(`form.requiredFundAmount`, lang)}
                  as={'input'}
                  type="number"
                  className={errors['requiredFundAmount'] ? 'error' : ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('requiredFundAmount', e.target.value, setFieldValue)}
                  value={formData.requiredFundAmount}
                  min={0}
                  max={type === 'SNP_IMPROVEMENT' ? 999 : 100000000}
                  step={'0.001'}
                />
              </FormGroup>
            </div>

            <label htmlFor="">{t('form.requiredFundSource', lang)}</label>


            {formData.requiredFund && Object.keys(formData.requiredFund).map((key) => {
              return (
                <div className="row sb" key={key}>
                  <FormGroup>
                    <label htmlFor={`requiredFund.${key}`}>{formData.requiredFund[key][`label${lang}`]}</label>
                    <Field
                      id={`requiredFund.${key}.value`}
                      name={`requiredFund.${key}.value`}
                      value={formData.requiredFund[key].value}
                      onChange={
                        (e: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(`requiredFund.${key}.value`, e.target.value)
                          setFormData({ ...formData, requiredFund: { ...formData.requiredFund, [key]: { ...formData.requiredFund[key], value: e.target.value.replace(' ', '') } } })
                        }}
                      className={errors[key] ? 'error' : ''}
                    />
                  </FormGroup>
                  <FooterButton variant='delete' type='button' onClick={() => onDelete(key)}>x</FooterButton>
                </div>
              )
            })}


            {tempFund.isAdding && <div className="row sb">
              <FormGroup>
                <input
                  type="text"
                  value={tempFund[`label${lang}`]}
                  onChange={(e) => setTempFund({ ...tempFund, [`label${lang}`]: lowerAndTrim(e.target.value) })}
                  placeholder={t('source', lang)}
                  className={tempFund.labelError ? 'error' : ''}
                />
                <input
                  type="text"
                  value={tempFund.value}
                  onChange={(e) => setTempFund({ ...tempFund, value: e.target.value.replace(' ', '') })}
                  placeholder={t('sum', lang)}
                  className={tempFund.valueError ? 'error' : ''}
                />
              </FormGroup>
            </div>}

            <div className="add-info__buttons">
              {tempFund.isAdding
                ? <>
                  <FormBlockButton onClick={() => onAdd()}>{t('save', lang)}</FormBlockButton>
                  <FormBlockButton onClick={handleCancelAdd}>{t('cancel', lang)}</FormBlockButton>
                </>
                : <FormBlockButton onClick={() => setTempFund({ ...tempFund, isAdding: true })}>
                  <AddField /> {t('form.add_fund', lang)}
                </FormBlockButton>
              }
            </div>
          </>
        }
      </FormBlock>
    </>
  )
}

export default DocumentationForm