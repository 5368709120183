import React from 'react'
import { BasicStatsItem } from '../index.styles'

const TestIframe = () => {
  const link = 'https://map.iaqmola.kz/'
  return (
    <BasicStatsItem
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        flex: '1',
        minHeight: 'calc(100vh - 4.4rem)'
      }}>
      <iframe title="TestIframe iframe" src={link} />
    </BasicStatsItem>
  )
}

export default TestIframe