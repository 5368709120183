import React, { useState } from 'react'
import DistrictItem from './district-item.component';

import { ArrowDown, Pen } from '../../assets/icons';

import { RegionWrapper } from './index.styles';
import { ISettlement } from '../../interfaces/snp.interface';
import { useTranslation } from 'react-i18next';
import { getLang, OBLAST_KATO } from '../../utils/helpers.utils';

interface IProps {
  region: ISettlement;
  onClick: (item: any) => void;
  isEditable?: boolean;
}

const RegionItem: React.FC<IProps> = ({ region, onClick, isEditable }) => {
  const { t, i18n: { language } } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => {
    region.kato !== OBLAST_KATO && setIsOpen(!isOpen)
  }

  return (
    <RegionWrapper isOpen={isOpen} lg={language} >
      <div className="name" onClick={toggleOpen}>
        {region.kato !== OBLAST_KATO && <ArrowDown />}
        <span className='primary'>
          {region[`name${getLang()}`].trim()}
          {<Pen className='pen' onClick={() => onClick(region)} />}
        </span>
        <span className='secondary first'>{t('totalVillages')}: {region.statistics.total}</span>
        <span className='secondary second'>{t('inProcessVillages')}: {region.statistics.inProcess}</span>
        <span className='secondary third'>{t('completedVillages')}: {region.statistics.completed}</span>
        <span className='secondary fourth'>{t('requiredVillages')}: {(region.statistics.required)}</span>
        <span className='secondary'>{t('activePercentage')}: {isNaN(region.statistics.completionPercent) ? 0 : `${region.statistics.completionPercent.toFixed(2)}`}%</span>
      </div>
      {region.kato !== OBLAST_KATO && isOpen && region.children &&
        <div className='districts'>
          {
            region.children.sort((a, b) => a[`name${getLang()}`].localeCompare(b[`name${getLang()}`])).map((item) =>
              <DistrictItem key={item[`name${getLang()}`]} district={item} onClick={onClick} />)
          }
        </div>
      }
    </RegionWrapper>
  )
}

export default RegionItem