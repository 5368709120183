import React from 'react'
import { BasicStatsItem } from '../index.styles'

const PE = () => {
  const link = 'https://app.powerbi.com/view?r=eyJrIjoiODEzOTQ4OTEtYmE5Yi00OWQ4LThjYTItYzU4YjMxOGVmMDc1IiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9'
  return (
    <BasicStatsItem
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        flex: '1',
        minHeight: 'calc(100vh - 4.4rem)'
      }}>
      <iframe title="PE iframe" src={link} />
    </BasicStatsItem>
  )
}

export default PE