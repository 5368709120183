import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

import { getProjectList } from '../../requests/snp.request';
import { EducationContainer, EducationItem } from './index.styles';

import 'react-toastify/dist/ReactToastify.css';
import { transformList } from './education.component';

import Documentation from './documentation.component';
import ImageGrid from './infrastructure-projects/image-grid-v2.component';
import ZoomableImageModal from './zoomable-image.component';
import AdditionalFields from './additional-fields.component';
import { ArrowDown, EduMinus, EduPlus } from '../../assets/icons';
import { checkNonEmpty, checkVal } from '../../utils/helpers.utils';
import { Text } from '../text.component';

const Internet = ({ kato, contentRef }: { kato: number, contentRef: any }) => {
  const { t } = useTranslation();

  const [projectList, setProjectList] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImageList, setCurrentImageList] = useState<any[]>([]);
  const [isVisible, setIsVisible] = useState<Record<number, boolean>>({});
  const [objectName, setObjectName] = useState<string>('Фото');

  const openImageModal = (image: any, images: any, name: string) => {
    setIsOpen(true);
    setSelectedImage(image);
    const imageIdList = images.reduce((acc: any, item: any) => [...acc, ...item.imageIds], []);
    setCurrentImageList(imageIdList);
    setObjectName(name);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
    setCurrentImageList([]);
  }

  const handleOpenProject = (id: number) => {
    setIsVisible(prev => ({ ...prev, [id]: !prev[id] }));
  }

  useEffect(() => {
    getProjectList('internet-communication', kato)
      .then((res) => {
        setProjectList(transformList(res));
        const obj = res.reduce((acc: any, item: any) => {
          acc[item.id] = res.length <= 3;
          return acc;
        }, {})
        setIsVisible(obj);
      })
      .catch((err) => {
        toast.error('Снп не выбрано');
      })
  }, [kato])

  if (projectList.length === 0) {
    return <p>{t('no-objects')}</p>
  }

  return (
    <>
      <EducationContainer ref={contentRef}>
        {projectList.length > 0 && projectList.map((project) =>
          <EducationItem key={project.id}>
            <div className="heading with-icon" onClick={() => handleOpenProject(project.id)}>
              <div className="education-icon">
                {isVisible[project.id] ? <EduMinus /> : <EduPlus />}
              </div>
              <Text color="#118f84" fontSize="0.875rem" fontWeight={500}>{project.communicationProvider?.name || `${t('object-name')} ${project.id}`}</Text>
            </div>

            {
              isVisible[project.id] &&
              <div className='body'>

                <div className="items">
                  {
                    checkVal(project.subscriberNumber) && <div className="item">
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('form.subscriberNumber')}:</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project.subscriberNumber}  </Text>
                    </div>
                  }
                  {
                    checkVal(project.requirements) && <div className="item">
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('form.requirements')}:</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project.requirements}  </Text>
                    </div>
                  }
                  {
                    checkVal(project.factCondition) && <div className="item">
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('form.factCondition')}:</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{t(project.factCondition.toLowerCase())}  </Text>
                    </div>
                  }
                </div>

                {(project[`repairRequired`] === 'true' || project[`repairRequired`] === true) &&
                  <div className='text-block'>
                    <Documentation project={project} showFunds={false} />
                  </div>
                }

                {checkVal(project.additionalFields) && Object.keys(project.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key)).length > 0 && <AdditionalFields project={project} />}

                {project.images && <ImageGrid images={project.images} openImageModal={openImageModal} name={project.communicationProvider?.name || `${t('object-name')} ${project.id}`} />}
              </div>
            }
          </EducationItem>
        )}
        <ToastContainer />
        {selectedImage && (
          <ZoomableImageModal
            isFileUpload={true}
            isOpen={isOpen}
            onClose={closeImageModal}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            currentImageList={currentImageList}
            objectName={objectName}
          />
        )}
      </EducationContainer>
    </>
  )
}

export default Internet