import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { getProjectList } from '../../requests/snp.request';
import { AgricultureWrapper, EducationContainer, EducationItem } from './index.styles';
import { transformList } from './education.component';
import ImageGrid from './infrastructure-projects/image-grid-v2.component';
import ZoomableImageModal from './zoomable-image.component';
import AdditionalFields from './additional-fields.component';
import { checkNonEmpty, checkVal, getLang } from '../../utils/helpers.utils';
import { ArrowDown, EduMinus, EduPlus } from '../../assets/icons';
import { useParams } from 'react-router-dom';
import { Text } from '../text.component';

const Investment = () => {
  const { t, i18n: { language } } = useTranslation();
  const { kato } = useParams()

  const [projectList, setProjectList] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImageList, setCurrentImageList] = useState<any[]>([]);
  const [objectName, setObjectName] = useState<string>('Фото')
  const [isAllOpen, setIsAllOpen] = useState<boolean>(false);
  const [isListOpen, setIsListOpen] = useState<{ [key: string]: boolean }>({});

  const handleOpen = (id: any) => {
    setIsListOpen(prev => ({ ...prev, [id]: !prev[id] }));
  }

  const handleOpenAll = () => {
    const obj = Object.keys(isListOpen).reduce((acc: any, key: any) => {
      acc[key] = !isAllOpen;
      return acc;
    }, {})

    setIsListOpen(obj);
  }

  useEffect(() => {
    const obj = projectList.reduce((acc: any, street: any) => {
      acc[street.id] = projectList.length < 3;
      return acc;
    }, {})
    setIsListOpen(obj);

    setIsAllOpen(Object.values(obj).every((value: any) => value === true));
  }, [projectList])

  useEffect(() => {
    setIsAllOpen(Object.values(isListOpen).every((value: any) => value === true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(isListOpen)])

  const openImageModal = (image: any, images: any, name: string) => {
    setIsOpen(true);
    !isNaN(image) ? setSelectedImage(image) : setSelectedImage(image.imageId)
    const imageList = (images.reduce((acc: any, item: any) => [...acc, ...item.imageIds], []));
    setCurrentImageList(imageList);
    setObjectName(name);
  }


  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
    setCurrentImageList([]);
  }

  useEffect(() => {
    const snpData = JSON.parse(localStorage.getItem('snp') as string);
    const val = kato ? kato : snpData.kato
    if (val) {
      getProjectList('SNP_INVESTMENT', val)
        .then((res) => {
          const filtered = res.filter((project: any) => Object.keys(project).some((key) => !['id', 'kato'].includes(key) && checkVal(project[key]) && checkNonEmpty(project[key])));
          setProjectList(transformList(filtered));
        })
        .catch((err) => {
          toast.error('Снп не выбрано');
        })
    }
  }, [])

  useEffect(() => {
    kato && getProjectList('SNP_INVESTMENT', +kato)
      .then((res) => {
        const filtered = res.filter((project: any) => Object.keys(project).some((key) => !['id', 'kato'].includes(key) && checkVal(project[key]) && checkNonEmpty(project[key])));
        setProjectList(transformList(filtered));
      })
      .catch((err) => {
        toast.error('Снп не выбрано');
      })
  }, [kato])

  if (projectList.length === 0) {
    return <p>{t('no-objects-investment')}  </p>
  }

  return (
    <AgricultureWrapper>
      {/* <div className='list-heading with-icon' onClick={handleOpenAll}>
        {t('list')}
        <ArrowDown width="12" style={{ transform: isAllOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
      </div> */}
      <EducationContainer>
        {projectList.length > 0 && projectList.map((project) =>
          <EducationItem key={project.id}>
            <div className="heading with-icon" onClick={() => handleOpen(project.id)}>
              <div className="education-icon">
                {isListOpen[project.id] ? <EduMinus /> : <EduPlus />}
              </div>
              <Text color="#118f84" fontSize="0.875rem" fontWeight={500}>{project[`name${getLang()}`] || `${t('object-name')} ${project.id}`}</Text>
              <div className="year">
                <Text color="rgba(0, 1, 5, 0.5)" fontSize="0.875rem" fontWeight={400}>{t('snp_investment.realizationYear')}</Text>
                <Text color="rgba(0, 1, 5, 1)" fontSize="0.875rem" fontWeight={500}>{project.realizationYear}</Text>
              </div>
            </div>

            {isListOpen[project.id] && <div className='body'>
              <div className='items'>
                {
                  [`projectPower${getLang()}`, 'investmentFund', 'workPlaceNumber'].some((key) => checkVal(project[key]))
                  && <>
                    {
                      checkVal(project[`projectPower${getLang()}`])
                      && <div className='item'>
                        <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('snp_investment.projectPower')}:</Text>
                        <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project[`projectPower${getLang()}`]}</Text>
                      </div>
                    }
                    {
                      checkVal(project[`investmentFund`])
                      && <div className='item'>
                        <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('snp_investment.investmentFund')}:</Text>
                        <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project.investmentFund} млн {t('tenge')}</Text>
                      </div>
                    }
                    {
                      checkVal(project.workPlaceNumber)
                      && <div className='item'>
                        <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('snp_investment.workPlaceNumber')}:</Text>
                        <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project.workPlaceNumber}</Text>
                      </div>
                    }
                  </>
                }
                {
                  (project[`shortDescription${language[0].toUpperCase() + language.slice(1)}`] || project[`expectedEffect${language[0].toUpperCase() + language.slice(1)}`]) && <>
                    <div className="item">
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('snp_investment.shortDescription')}:</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project[`shortDescription${language[0].toUpperCase() + language.slice(1)}`]}</Text>
                    </div>

                    <div className="item">
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('snp_investment.expectedEffect')}:</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project[`expectedEffect${language[0].toUpperCase() + language.slice(1)}`]}</Text>
                    </div>
                  </>
                }
              </div>

                
              {checkVal(project.additionalFields) && Object.keys(project.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key)).length > 0 && <AdditionalFields project={project} />}

              {project.images && <ImageGrid images={project.images} openImageModal={openImageModal} name={project[`name${getLang()}`] || `${t('object-name')} ${project.id}`} />}

            </div>}

          </EducationItem>
        )}
        <ToastContainer />
      </EducationContainer>
      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={currentImageList}
          objectName={objectName}
        />
      )}
    </AgricultureWrapper>

  )
}

export default Investment