import styled from "styled-components";

export const Cards = styled.div`
  display: flex;
  gap: 0.3125rem;
  flex-direction: column;
  justify-content: center;
  height: 100%; 

  .card {
    padding: 0.5rem;
    background: var(--bg-color);
    border: 1px solid var(--primary-color);
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
    &__header {
      display: flex;
      justify-content: space-between;
      text-align: center;
      white-space: nowrap;

      > div:first-child {
        text-align: left;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.3125rem;
      justify-items: right;

      .tal {
        justify-self: left;
      }

      .increase {
        display: flex;
        gap: 0.3125rem;
        align-items: baseline;
      }
    }
  }

  .grid.screening-grid { 
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    gap: 3px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      align-items: center;
      justify-content: center;
    }

    .value {
      display: flex;
      gap: 0.25rem;
      align-items: baseline;
    }

    .percents {
      display: flex;
      justify-content: space-between;
    }
  }
`

export const ScreeningContainer = styled.div<{ isFull?: boolean }>`
  display: flex;
  flex-direction: column;

  .tabs {
    width: 100%;
    background-color: #f6f6f6;
    display: flex;
    padding: 0.625rem 1.25rem;
    align-items: center;
    height: 3.125rem;
    gap: 0.625rem;
    border-bottom: 1px solid #DBDCE0;

    .tab {
      padding: 0 1rem;
      background: #E2E2E2;
      border-radius: 0.3125rem;
      font-size: 0.875rem;
      color: var(--text-color);
      cursor: pointer;
      white-space: nowrap;
      height: 2rem;
      display: flex;
      align-items: center;      

      &.active {
        background: linear-gradient(90deg, #226AC3 0%, #04A958 100%);
        color: #fff;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    > div {
      max-width: ${({ isFull }) => isFull ? '100%' : '99%'}; 
    }

    &.c-2 {
      grid-template-columns: repeat(2, 1fr);
      gap: 1.25rem;
    }

    &.c-3 {
      grid-template-columns: repeat(3, 1fr);
      gap: 1.25rem;
    }

    &.equal-rows {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-auto-rows: 1fr; 

      > div {
        max-width: 100%; 
      }
    }
  }

  table.screening-table {
    border-collapse: collapse;
    margin-top: 0.625rem;
    margin-bottom: 1.25rem;
    
    td,
    th {
      height: 2.5rem;
      border-bottom: 1px solid #DBDCE0;
      border-right: 1px solid #DBDCE0;
      padding-left: 1rem;
    }

    tr {
      th, 
      td {
        &:last-child {
          border-right: none;
        }
      }
    }

    tr:last-child {
      th,
      td {
        border-bottom: none;
      }
    }

    thead th {
      text-align: left;
    }

    tbody {
      tr:nth-child(odd) {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .mcb-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;

    .value {
      display: flex;
      gap: 0.625rem;
      align-items: baseline;
    }
  }

  table.mcb-table {
    border-collapse: collapse;
    margin-top: 0;
    margin-bottom: 1.25rem;

    td,
    th {
      border-bottom: 1px solid #DBDCE0;
      padding-left: 1rem;
    }

    th {
      height: 3.125rem;
      text-align: left;
    }

    tbody {
      td {
        height: 4.25rem;
      }
    }
  }
  `;