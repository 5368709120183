import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from '../../text.component';
import { useTranslation } from 'react-i18next';
import { SER1, SER3, SER32, SER33, SER6, SER7 } from '../../../assets/icons/ser';
import { SortIcon2 } from '../../../assets/icons';
import { getOperstabData } from '../../../requests/kato.request';
import { IOpershtabRequest } from '../../../interfaces/snp.interface';

const OperStabContainer = styled.div`
  display: flex;
  flex-direction: column;

  .filters {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    height: 50px;
    padding-left: 1.25rem;
    background-color: #fff;

    select {
      height: 2rem;
      border: 1px solid #DBDCE0;
      border-radius: 5px;
      font-size: 0.75rem;
      width: 150px;
      &:focus {
        outline: none;
      }
    }
  }

  .operstab-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.625rem;
    padding: 1.25rem;
  }

  .regions {
    border: 1px solid #CFCFCF;
    border-radius: 5px;

    .header {
      svg {
        cursor: pointer;
      }

      .item {
        height: 34px;
        width: 100%;
        background-color: #F6F6F6;  
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.3125rem;
      }
    }

    .region {
      height: 40px;
      border-top: 1px solid #CFCFCF;
      display: flex;
      align-items: center;
      padding: 0 0.3125rem;

      &:nth-child(odd) {
        background-color: #FFF;
      }
    }
  }

  .values { 
    border: 1px solid #CFCFCF;
    .header {
      display: flex;
      flex-direction: column;

      &-top {
        background-color: #118f84;
        display: flex;
        align-items: center;
        gap: 0.3125rem;
        justify-content: center;
        height: 34px;

        > div: last-child {
          white-space: wrap-all;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          max-width: 6rem;
          padding-right: 0.3125rem; 
          overflow-wrap: anywhere;
        }

        svg path {
          stroke: #fff;
        }
      }

      &-bot {
        display: flex;
        align-items: center;
        border-top: 1px solid #CFCFCF;
        height: 34px;

        > div {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #CFCFCF;
          border-bottom: 1px solid #CFCFCF;
          height: 100%;
           
        }
      }
    }

    .list {
      display: flex;
      width: 100%;
      flex-direction: column;

      .data {
        height: 40px;
        display: flex;
        align-items: center;

        &:nth-child(odd) {
          background-color: #FFF;
        }

        > div {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #CFCFCF;
          border-bottom: 1px solid #CFCFCF;
          height: 100%;
        }

        .fact {
          &--red {
            color: #C57979;
            background: #C5797933;
          }

          &--green {
            color: #118f84;
            background: #118F8433;
          } 
        }
      }
    }
  }
`;

const quartals = ['Q1', 'Q2', 'Q3', 'Q4'];
const keys = ['PROCESSING', 'BUILDING', 'TRADING', 'FARMING', 'MANUFACTURING', 'HOUSING'];

const keyToIcon: any = {
  PROCESSING: <SER3 />,
  BUILDING: <SER7 />,
  TRADING: <SER32 />,
  FARMING: <SER33 />,
  MANUFACTURING: <SER1 />,
  HOUSING: <SER6 />
}

interface ITransformedData {
  name: string;
  values: {
    [key: string]: {
      planQ1: number;
      planQ2: number;
      planQ3: number;
      planQ4: number;
      factQ1: number;
      factQ2: number;
      factQ3: number;
      factQ4: number;
    };
  };
}

const transformData = (input: IOpershtabRequest[]): ITransformedData[] => {
  return input.reduce((acc: ITransformedData[], curr) => {
    let region = acc.find((item) => item.name === curr.nameRu);
    if (!region) {
      region = { name: curr.nameRu, values: {} };
      acc.push(region);
    }
    region.values[curr.subType] = { ...curr.additionalInfo };

    return acc;
  }, []);
}


const Operstab = () => {
  const { t } = useTranslation();
  const [selectedQuartal, setSelectedQuartal] = useState('Q1');
  const [tableData, setTableData] = useState<ITransformedData[]>();
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [operstabData, setOperstabData] = useState<ITransformedData[]>();

  const getFactStyle = (values: any) => {
    if (+values['fact' + selectedQuartal] < +values['plan' + selectedQuartal]) {
      return 'red';
    } else {
      return 'green';
    }
  }

  const handleSort = () => {
    if (operstabData) {
      const direction = sortDirection === 'asc' ? 'desc' : 'asc';
      const sortedData = [...operstabData].sort((a, b) => {
        if (a.name < b.name) return direction === 'asc' ? -1 : 1;
        if (a.name > b.name) return direction === 'asc' ? 1 : -1;
        return 0;
      });
      setSortDirection(direction);
      setTableData(sortedData);
    }
  };

  useEffect(() => {
    getOperstabData().then((res) => {
      setTableData(transformData(res));
      setOperstabData(transformData(res));
    })
  }, []);

  if (!tableData) return <></>;

  return (
    tableData && <OperStabContainer>
      <div className="filters">
        <Text fontSize="0.75rem" fontWeight={700} color='#118f84'>Достижение целевых индикаторов за</Text>
        <select name="quartals" id="quartals" onChange={(e) => setSelectedQuartal(e.target.value)}>
          {
            quartals.map((quartal, index) => (
              <option key={index} value={quartal}>{t('operstab-page.' + quartal)}</option>
            ))
          }
        </select>
      </div>

      <div className="operstab-grid">
        <div className="regions">
          <div className="header">
            <div className="item"></div>
            <div className="item">
              <Text fontSize="0.75rem" fontWeight={400} color='#7C7E7C'>Район</Text>
              <SortIcon2 onClick={handleSort} />
            </div>
          </div>
          <div className="list">
            {
              tableData.map((region: any, index: number) => (
                <div key={index} className="region">
                  <Text fontSize="0.75rem" fontWeight={700} color='#118f84'>{region.name}</Text>
                </div>
              ))
            }
          </div>
        </div>

        {
          keys.map((key, index) => (
            <div className="values">
              <div className="header">
                <div className="header-top">
                  {keyToIcon[key]}
                  <Text fontSize="0.75rem" fontWeight={600} color='#fff'>{t('operstab-page.' + key)}</Text>
                </div>
                <div className="header-bot">
                  <Text fontSize="0.75rem" fontWeight={700} color='#7C7E7C'>План</Text>
                  <Text fontSize="0.75rem" fontWeight={700} color='#7C7E7C'>Факт</Text>
                </div>

                <div className="list">
                  {
                    tableData.map((region: any, index: number) => (
                      <div key={index} className="data">
                        <Text fontSize="0.75rem" className='plan' fontWeight={400} color='#000105'>{region.values[key]['plan' + selectedQuartal]}%  </Text>
                        <Text fontSize="0.75rem" className={`fact fact--${getFactStyle(region.values[key])}`} fontWeight={700} color='#118f84'>{region.values[key]['fact' + selectedQuartal]}%</Text>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </OperStabContainer>
    // <BasicStatsItem
    //   style={{
    //     width: '100%',
    //     height: '100%',
    //     minHeight: '100%',
    //     flex: 1,  
    //     border: 'none',
    //   }}>
    //   <iframe title="Operstab iframe" src={link} />
    // </BasicStatsItem>
  )
}

export default Operstab