
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getProjectList } from '../../requests/snp.request';

import { ArrowRight, Plus } from '../../assets/icons';

import { AddEducationObject, EducationObject, EducationReportContainer, InfrastructureButtonsContainer } from './infrastructure.styles';
import { IProject } from '../../interfaces/snp.interface';
import { getLang } from '../../utils/helpers.utils';

const types: { [key: string]: string } = {
  'snp_improvement': 'SNP_IMPROVEMENT',
  'snp_investment': 'SNP_INVESTMENT',
}

const Reports = () => {
  const params = useParams();
  const { type, kato } = params
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [list, setList] = useState<IProject[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addNewObject = () => {
    navigate(`new`)
  }

  const handleObjectClick = (id: number) => {
    navigate(`${id}`);
  }

  useEffect(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;
    if (val && type) {
      setIsLoading(true)

      const t = ['snp_improvement', 'snp_investment'].includes(type) ? types[type] : type

      t && getProjectList(t, val).then((res) => {
        setList(res)
      })
        .finally(() => setIsLoading(false))
    }
  }, [type])

  return (
    <>
      {!isLoading && list && list.length > 0 && <EducationReportContainer>
        {list && list.length > 0 && list.map((item) =>
          <EducationObject key={item.id} onClick={() => handleObjectClick(item.id)}>
            {
              type === 'internet-communication'
                ? item.communicationProvider && item.communicationProvider.name
                && item.communicationProvider.name
                : (type === 'village-road' || type === 'lighting')
                  ? t(`report_types.${type}`)
                  : (item[`name${getLang()}`]) || `${t('object-name')} ${item.id}`
            }
            <ArrowRight height={12} />
          </EducationObject>
        )}
      </EducationReportContainer>
      }
      {
        (type === 'village-road' || type === 'lighting') && list && list.length > 0
          ? <></>
          : <InfrastructureButtonsContainer>
            <AddEducationObject onClick={() => addNewObject()} >
              <Plus height={14} />
              {t('addNewObject')}
            </AddEducationObject>

          </InfrastructureButtonsContainer>
      }


    </>
  )
}

export default Reports