import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';

import ImageGrid from '../insfrastructureProjects/image-grid.component';

import { getProject } from '../../requests/snp.request';
import { createObject, updateObject } from '../../requests/supervisor.request';

import { IProject } from '../../interfaces/snp.interface';

import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import { checkAddKeys, checkTempVal, checkVal, getLang, lowerAndTrim, t } from '../../utils/helpers.utils';
import FormFooter from '../insfrastructureProjects/footer.component';
import { ITempFund, ITempVal, documentationKeys, errMsg } from '../insfrastructureProjects/education-form.component';
import DocumentationForm from '../formComponents/documentation-form.component';
import { FormGroup, Wrapper } from '../admin-page/admin-page.styles';
import LinksForm from '../formComponents/links-form.component';

const initialFormData = {
  totalSum: 0
}

const checkList = [
  "nameKz",
  "nameRu",
  "documentationStatus"
]

const VillageImprovementForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [tempVal, setTempVal] = useState<ITempVal>({
    isAdding: false,
    labelKz: '',
    labelRu: '',
    valueKz: '',
    valueRu: '',
    isAddingText: false,
    textKz: '',
    textRu: '',
    labelError: false,
    valueError: false,
    textError: false,
    isAddingLink: false,
    link: '',
    linkLabelRu: '',
    linkLabelKz: '',
    linkError: false
  });
  const [tempFund, setTempFund] = useState<ITempFund>({
    labelKz: '',
    labelRu: '',
    value: '',
    isAdding: false,
    labelError: false,
    valueError: false,
    streetId: null
  })

  const handleSave = (values: IProject) => {
    setErrors({})
    setTempVal({ ...tempVal, textError: false, labelError: false, valueError: false })
    setTempFund({ ...tempFund, labelError: false, valueError: false })

    let data: any = {
      ...values,
      additionalFields: { ...formData.additionalFields },
    }

    for (const key of checkList) {
      if (!checkVal(data[key])) {
        setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
        toast.error(t(`errors.${errMsg[key]}`, language))
        return
      }
    }

    if (["HAVE"].includes(data.documentationStatus)) {
      for (const key of documentationKeys.filter(item => !['requiredFund', 'documentationStatus'].includes(item))) {
        if (!checkVal(data[key])) {
          setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
          toast.error(t(`errors.${errMsg[key]}`, language))
          return
        }
      }
    }

    // if (formData.files.length === 0) {
    //   setErrors((prev: any) => ({ ...prev, files: true }))
    //   toast.error(t(`errors.files`, language))
    //   return
    // }

    const updatedData = checkTempVal(tempVal, setTempVal, formData, setFormData, data);

    if (!updatedData) {
      return;
    }

    data = updatedData;

    checkAddKeys(data, setErrors);

    if (tempFund.isAdding) {
      if (!checkVal(tempFund.labelKz) || !checkVal(tempFund.labelRu)) {
        setTempFund((prev: any) => ({ ...prev, labelError: true }))
        toast.error(t(`errors.additional-field`, language))
        return
      }

      if (!checkVal(tempFund.value)) {
        setTempFund((prev: any) => ({ ...prev, valueError: true }))
        toast.error(t(`errors.additional-value`, language))
        return
      }

      data = {
        ...data,
        requiredFund: addFund()
      }
    }

    const fund_keys = data.requiredFund && Object.keys(data.requiredFund).filter((key: string) => !['ru', 'kz'].includes(key));
    if (fund_keys && data.repairRequired) {
      for (const key of fund_keys) {
        if (!checkVal(data.requiredFund[key].value)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      }
    }

    updateObject('SNP_IMPROVEMENT', data)
      .then(res => toast.success(t(`toast.save_success`, language)))
      .catch(err => toast.error(t(`toast.save_error`, language)))
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('SNP_IMPROVEMENT', objectId).then(res => {
      setFormData(res.infrastructureEntity)
      setImages(res.images)
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    console.log('first')

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('SNP_IMPROVEMENT', val).then(res => {
        navigate(`/admin/${val}/snp_improvement/${res.id}`);
      })
    }
  }, [navigate, objectId, kato])

  const deleteField = (key: string) => {
    const { [key]: deleted, ...rest } = formData.additionalFields;
    setFormData({ ...formData, additionalFields: rest })
    setTempVal({ ...tempVal, isAdding: false, labelError: false, valueError: false })
    updateObject('SNP_IMPROVEMENT', { ...formData, additionalFields: rest })
      .then(() => loadForm())
  }

  const addFund = () => {
    if (!checkVal(tempFund.labelKz) || !checkVal(tempFund.labelRu)) {
      setTempFund((prev: any) => ({ ...prev, labelError: true }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempFund.value)) {
      setTempFund((prev: any) => ({ ...prev, valueError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const { labelKz, labelRu, value } = tempFund;

    const field = { ...formData.requiredFund, [+new Date()]: { labelKz, labelRu, value } }

    const body = {
      ...formData,
      requiredFund: field
    }

    setFormData(body)
    setTempFund({ labelRu: '', labelKz: '', value: '', isAdding: false, labelError: false, valueError: false })

    return field;
  }

  const deleteFund = (key: string) => {
    const { [key]: deleted, ...rest } = formData.requiredFund;

    const body = {
      ...formData,
      requiredFund: rest
    }

    setFormData(body)
    updateObject('SNP_IMRPOVEMENT', body).then(() => {
      loadForm()
    })
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormGroup >
        <label className='required' htmlFor={`name${lang}`}>{t(`snp_improvement.name`, lang)}</label>
        <Field
          id={`name${lang}`}
          name={`name${lang}`}
          placeholder={t(`snp_improvement.name`, lang)}
          onChange={(e: any) => setFieldValue(`name${lang}`, lowerAndTrim(e.target.value))}
          className={errors['objectName'] ? 'error' : ''}
        />
      </FormGroup>

      <DocumentationForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        type='SNP_IMPROVEMENT'
        onAdd={addFund}
        tempFund={tempFund}
        setTempFund={setTempFund}
        onDelete={deleteFund}
        errors={errors}
      />

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        onSave={handleSave}
        setTempVal={setTempVal}
        errors={errors}
        tempVal={tempVal}
        onDelete={deleteField}
        type='improvement'
      />

      <LinksForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        onSave={handleSave}
        setTempVal={setTempVal}
        errors={errors}
        tempVal={tempVal}
        onDelete={deleteField}
        type='improvement'
      />
    </>
  }

  return (
    <Wrapper ref={wrapperRef}>
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="grid-item">
                  {renderFields(getLang(), setFieldValue)}
                </div>
                <div className="grid-item">
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                </div>
                <FormFooter type='SNP_IMPROVEMENT' formId={formData.id} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />
      <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'SNP_IMPROVEMENT'}
        images={images}
        lang={language}
      />
    </Wrapper >
  )
}

export default VillageImprovementForm