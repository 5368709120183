import React, { useEffect } from 'react'
import { DownloadIcon, Expand, ImageArrowLeft, ImageArrowRight, ImageZoomIn, ImageZoomOut } from '../../assets/icons';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ZoomableImageContainer } from './index.styles';
import Modal from '../modal-component';
import { getImageUrl } from '../admin-page/general-info.component';
import { Text } from '../text.component';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedImage: number | null;
  setSelectedImage: (imageId: number) => void;
  imageInfo?: any;
  isFileUpload?: boolean;
  currentImageList: number[]
  objectName?: any;
}

const ZoomableImageModal: React.FC<IProps> = ({ isOpen, onClose, isFileUpload, selectedImage, setSelectedImage, currentImageList, imageInfo, objectName }) => {
  const { t } = useTranslation();
  const handleNextImage = () => {
    if (selectedImage) {
      const currentIndex = currentImageList.indexOf(selectedImage);
      if (currentIndex < currentImageList.length - 1) {
        setSelectedImage(currentImageList[currentIndex + 1]);
      }
    }
  }

  const handlePrevImage = () => {
    if (selectedImage) {
      const currentIndex = currentImageList.indexOf(selectedImage);
      if (currentIndex > 0) {
        setSelectedImage(currentImageList[currentIndex - 1]);
      }
    }
  }

  const handleDownload = () => {

  }

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.code === 'ArrowRight') {
        handleNextImage()
      }

      if (e.code === 'ArrowLeft') {
        handlePrevImage()
      }
    })
  }, [selectedImage])

  useEffect(() => {
    console.log(currentImageList, selectedImage)
  }, [selectedImage, currentImageList])

  return (
    <Modal isFileUpload={isFileUpload} isOpen={isOpen} onClose={onClose} hasBg={false}>
      <ZoomableImageContainer>
        <ImageArrowLeft className={'arrow prev'} onClick={handlePrevImage} />
        <div className="modal-body">
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <div className="tools">
                  <button className='zoom' onClick={() => zoomIn()}><ImageZoomIn /></button>
                  <button className='zoom' onClick={() => zoomOut()}><ImageZoomOut /></button>
                </div>
                {selectedImage && <TransformComponent>
                  <img src={getImageUrl(+selectedImage)} alt="object" />
                </TransformComponent>}
              </>
            )}
          </TransformWrapper>
          <div className="footer">
            <Text fontSize='0.875rem' color="#BFBFBF">{imageInfo?.date}</Text>
            <div className='object-info'>
              <Text fontSize='0.875rem' color="#BFBFBF">{objectName}</Text>
              {currentImageList.length > 0 && <Text fontSize='0.875rem' color="#BFBFBF" style={{ whiteSpace: 'nowrap' }} >{selectedImage && currentImageList.indexOf(selectedImage) + 1} / {currentImageList.length}</Text>}
            </div>
            <div className='download' onClick={handleDownload}>
              <DownloadIcon />
            </div>
          </div>
        </div>

        <ImageArrowRight className={'arrow next'} onClick={handleNextImage} />


      </ZoomableImageContainer>
    </Modal>

  )
}

export default ZoomableImageModal