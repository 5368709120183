import React from 'react'
import { IProject } from '../../interfaces/snp.interface'
import { useTranslation } from 'react-i18next'
import { checkVal, formatDate, getLang } from '../../utils/helpers.utils'
import { Text } from '../text.component';

interface IProps {
  project: IProject;
  showFunds?: boolean
}

export const dateArray: ['workStart', 'workEnd'] = ['workStart', 'workEnd'];

const Documentation: React.FC<IProps> = ({ project, showFunds = true }) => {
  const { t } = useTranslation();

  return (
    (project.documentationStatus || project.requiredFund || project.workStart || project.workEnd) &&
    <>
      <div className="block">
        <Text color="#118F84" fontSize="0.875rem" fontWeight={400}>Документация</Text>
        <div className="items">
          {
            project.documentationStatus && ['NOT_HAVE', 'IN_PROCESS'].includes(project.documentationStatus) && <div className="item">
              <Text color="#818689" fontSize="0.875rem" fontWeight={400}>
                {project.documentationStatus === 'NOT_HAVE' && <>{t('documentation-yn')}</>}
                {project.documentationStatus === 'IN_PROCESS' && <>{t('documentation-yi')}</>}
              </Text>
            </div>
          }

          {
            project.documentationStatus === 'HAVE' && <>
              {
                dateArray.some(key => checkVal(project[key])) && <>
                  {
                    dateArray.map(key => (
                      project[key] && <div className="item" key={key}>
                        <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t(`form.${key}`)}:</Text>
                        <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{formatDate(project[key])}</Text>
                      </div>
                    ))
                  }
                </>
              }

              {
                checkVal(project[`requiredFundSource${getLang()}`]) && <div className="item">
                  <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t('form.requiredFundSource')}:</Text>
                  <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project[`requiredFundSource${getLang()}`]}</Text>
                </div>
              }

              {
                checkVal(project[`requiredFundAmount`]) && <div className="item">
                  <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t('form.requiredFundAmount')}:</Text>
                  <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project[`requiredFundAmount`]}</Text>
                </div>
              }


            </>
          }
        </div>
      </div>

      {
        project.requiredFund && !!Object.keys(project.requiredFund).filter(key => !['ru', 'kz'].includes(key)).length && showFunds && <div className="block">
          {
            <>
              <Text color="#118F84" fontSize="0.875rem" fontWeight={400}>{t('documentation-additional-funds')}</Text>
              <div className="items">
                {
                  Object.keys(project.requiredFund).filter(key => !['ru', 'kz'].includes(key)).map(key => (
                    <div className="item">
                      <div className="text" key={key}>{project.requiredFund[key][`label${getLang()}`]} {project.requiredFund[key].value && project.requiredFund?.[key].value !== '0' && `: ${project.requiredFund[key].value} млн ${t('tenge')}`}</div>
                    </div>
                  ))
                }
              </div>

            </>
          }
        </div>
      }
    </>
  )
}

export default Documentation
