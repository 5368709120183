import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';

import { createObject, updateObject } from '../../requests/supervisor.request';
import { getProject } from '../../requests/snp.request';

import { Wrapper, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock } from './infrastructure.styles';

import { IProject } from '../../interfaces/snp.interface';

import 'react-toastify/dist/ReactToastify.css';
import ImageGrid from './image-grid.component';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import DocumentationForm from '../formComponents/documentation-form.component';
import { options, requirementsOptions } from '../../constants/snp.constant';
import { checkVal, lowerAndTrim, t } from '../../utils/helpers.utils';
import FieldsComponent from './fields.components';
import FormFooter from './footer.component';
import Tabs from './tabs.component';
import { ITempFund, ITempVal, documentationKeys, errMsg } from './education-form.component';
import { skipList } from './water-supply-form.component';

const initialFormData = {
  "id": 0,
  "kato": 0,
  "nameKz": "",
  "nameRu": "",
  "nameEn": "",
  "constructionYear": 0,
  "factInfo": "",
  "power": 0,
  "requirements": {},
  "documentationStatus": "",
  "requiredFundSource": "",
  "requiredFundAmount": 0,
  "workStart": "",
  "workEnd": ""
}

const GasSupplyForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [tempVal, setTempVal] = useState<ITempVal>({
    isAdding: false,
    labelKz: '',
    labelRu: '',
    valueKz: '',
    valueRu: '',
    isAddingText: false,
    textKz: '',
    textRu: '',
    labelError: false,
    valueError: false,
    textError: false,
    isAddingLink: false,
    link: '',
    linkLabelRu: '',
    linkLabelKz: '',
    linkError: false
  });

  const [tempFund, setTempFund] = useState<ITempFund>({
    labelKz: '',
    labelRu: '',
    value: '',
    isAdding: false,
    labelError: false,
    valueError: false
  })

  const handleSave = (values: IProject) => {
    setErrors({})
    setTempVal({ ...tempVal, textError: false, labelError: false, valueError: false })
    setTempFund({ ...tempFund, labelError: false, valueError: false })

    let data: any = {
      ...values,
      additionalFields: { ...formData.additionalFields },
      requiredFund: { ...values.requiredFund, ...formData.requiredFund },
      documentationStatus: values.documentationStatus === "" ? null : values.documentationStatus,
    }

    const keys = Object.keys(data).filter((key: string) => !skipList.includes(key));

    for (const key of keys) {
      if (!checkVal(data[key]) && typeof data[key] !== 'boolean') {
        setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
        toast.error(t(`errors.${errMsg[key]}`, language))
        return
      }
    }

    if (data.constructionYear > 2030 || data.constructionYear <= 0) {
      setErrors((prev: any) => ({ ...prev, constructionYear: true }))
      toast.error(t(`errors.yearRange`, language))
      return
    }

    if (data.requirements && data.requirements !== 'NOT_NEEDED') {
      if (!checkVal(data.requirementsKz) || !checkVal(data.requirementsRu)) {
        setErrors({ ...errors, exactly: true })
        toast.error(t(`errors.exactly`, language))
        return
      }

      if (!data.lastMajorRenovation) {
        setErrors({ ...errors, lastMajorRenovation: true })
        toast.error(t(`errors.fill-field`, language))
        return
      }

      if (checkVal(data.requirements && data.requirements !== 'NOT_NEEDED')) {
        if (!checkVal(data.documentationStatus)) {
          setErrors({ ...errors, documentationStatus: true })
          toast.error(t(`errors.documentationStatus`, language))
          return
        } else if (data.documentationStatus === 'HAVE') {
          for (const key of documentationKeys.filter(item => !['requiredFund', 'documentationStatus'].includes(item))) {
            if (!checkVal(data[key])) {
              setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
              toast.error(t(`errors.${errMsg[key]}`, language))
              return
            }
          }
        }
      }
    };

    if (tempVal.isAddingText) {
      if (!checkVal(tempVal.textRu) || !checkVal(tempVal.textKz)) {
        setTempVal({ ...tempVal, textError: true })
        toast.error(t(`errors.additional-value`, language))
        return
      }
      data = {
        ...data,
        additionalFields: addText()
      }
    }

    if (tempVal.isAdding) {
      if (!checkVal(tempVal.labelKz) || !checkVal(tempVal.labelRu)) {
        setTempVal({ ...tempVal, labelError: true })
        toast.error(t(`errors.additional-field`, language))
        return
      }

      if (!checkVal(tempVal.valueKz) || !checkVal(tempVal.valueRu)) {
        setTempVal({ ...tempVal, valueError: true })
        toast.error(t(`errors.additional-value`, language))
        return
      }

      data = {
        ...data,
        additionalFields: addField()
      }
    }

    const add_keys = Object.keys(data.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key))

    for (const key of add_keys) {
      if (key.includes('no-label')) {
        if (!checkVal(data.additionalFields[key].textKz) || !checkVal(data.additionalFields[key].textRu)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      } else {
        if (!checkVal(data.additionalFields[key].valueKz) || !checkVal(data.additionalFields[key].valueRu)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      }
    }

    if (tempFund.isAdding) {
      if (!checkVal(tempFund.labelKz) || !checkVal(tempFund.labelRu)) {
        setTempFund((prev: any) => ({ ...prev, labelError: true }))
        toast.error(t(`errors.additional-field`, language))
        return
      }

      if (!checkVal(tempFund.value)) {
        setTempFund((prev: any) => ({ ...prev, valueError: true }))
        toast.error(t(`errors.additional-value`, language))
        return
      }

      data = {
        ...data,
        requiredFund: addFund()
      }
    }

        const fund_keys = data.requiredFund && Object.keys(data.requiredFund).filter((key: string) => !['ru', 'kz'].includes(key));


    if (fund_keys && data.requirements && data.requirements !== 'NOT_NEEDED' && data.documentationStatus === 'HAVE') {
      for (const key of fund_keys) {
        if (!checkVal(data.requiredFund[key].value)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      }
    }

    updateObject('gas-supply', data)
      .then(res => toast.success(t(`toast.save_success`, language)))
      .catch(err => toast.error(t(`toast.save_error`, language)))
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('gas-supply', objectId).then(res => {
      setFormData(res.infrastructureEntity)
      setImages(res.images)
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('gas-supply', val).then(res => {
        navigate(`/admin/${val}/infrastructure-projects/gas-supply/${res.id}`);
      })
    }
  }, [navigate, objectId, kato])

  const addField = () => {
    if (!checkVal(tempVal.labelKz) || !checkVal(tempVal.labelRu)) {
      setTempVal((prev: any) => ({ ...prev, labelError: true }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempVal.valueKz) || !checkVal(tempVal.valueRu)) {
      setTempVal((prev: any) => ({ ...prev, valueError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const field = {
      ...formData.additionalFields,
      [+new Date()]: {
        labelKz: tempVal.labelKz,
        labelRu: tempVal.labelRu,
        valueKz: tempVal.valueKz,
        valueRu: tempVal.valueRu,
      }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })
    setTempVal({ ...tempVal, labelKz: '', valueKz: '', isAdding: false, labelRu: '', valueRu: '', labelError: false, valueError: false })
    return field
  }

  const addText = () => {
    if (!checkVal(tempVal.textRu) || !checkVal(tempVal.textKz)) {
      setTempVal((prev: any) => ({ ...prev, textError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const field = {
      ...formData.additionalFields,
      [`no-label-${+new Date()}`]: { textKz: tempVal.textKz, textRu: tempVal.textRu }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })

    setTempVal({ ...tempVal, textRu: '', textKz: '', isAddingText: false, textError: false })
    return field
  }

  const deleteField = (key: string) => {
    const { [key]: deleted, ...rest } = formData.additionalFields;
    setFormData({ ...formData, additionalFields: rest })
    updateObject('GAS_SUPPLY', { ...formData, additionalFields: rest })
      .then(() => loadForm())
  }

  const addFund = () => {
    if (!checkVal(tempFund.labelKz) || !checkVal(tempFund.labelRu)) {
      setTempFund((prev: any) => ({ ...prev, labelError: true }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempFund.value)) {
      setTempFund((prev: any) => ({ ...prev, valueError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const { labelKz, labelRu, value } = tempFund;

    const field = { ...formData.requiredFund, [+new Date()]: { labelKz, labelRu, value } }

    const body = {
      ...formData,
      requiredFund: field
    }

    setFormData(body)
    setTempFund({ labelRu: '', labelKz: '', value: '', isAdding: false, labelError: false, valueError: false })

    return field;
  }

  const deleteFund = (key: string) => {
    const { [key]: deleted, ...rest } = formData.requiredFund;

    const body = {
      ...formData,
      requiredFund: rest
    }

    setFormData(body)
    updateObject('GAS_SUPPLY', body).then(() => {
      loadForm()
    })
  }

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormGroup>
        <label className='required' htmlFor={`name${lang}`}>{t(`form.structureName`, lang)}</label>
        <Field
          id={`name${lang}`}
          name={`name${lang}`}
          placeholder={t(`form.structureName`, lang)}
          onChange={(e: any) => setFieldValue(`name${lang}`, lowerAndTrim(e.target.value))}
          className={errors['objectName'] ? 'error' : ''}
        />
      </FormGroup>

      <FormBlock>
        <div className="grid">
          <FormGroup>
            <label className='required' htmlFor={`constructionYear`}>{t(`form.constructionYear`, lang)}</label>
            <Field
              id={`constructionYear`}
              name={`constructionYear`}
              placeholder={t(`form.constructionYear`, lang)}
              as="input"
              type="number"
              className={errors['constructionYear'] ? 'error' : ''}
            />
          </FormGroup>
          <FormGroup>
            <label className='required' htmlFor={`factInfo`}>{t(`form.factInfo`, lang)}</label>
            <Field
              id={`factInfo`}
              name={`factInfo`}
              placeholder={t(`form.factInfo`, lang)}
              className={errors['factInfo'] ? 'error' : ''}
            />
          </FormGroup>
          <FormGroup>
            <label className='required' htmlFor={`power`}>{t(`form.power`, lang)}</label>
            <Field
              id={`power`}
              name={`power`}
              placeholder={t(`form.power`, lang)}
              as="input"
              type="number"
              className={errors['power'] ? 'error' : ''}
            />
          </FormGroup>
        </div>
      </FormBlock>

      <FormBlock>
        <FormGroup>
          <label className='required' htmlFor={`requirements`}>{t(`requirementsOptions.required`, lang)}</label>
          <select
            name="requirements"
            id="requirements"
            onChange={(e: any) => {
              setFieldValue('requirements', e.target.value);
              setFormData({ ...formData, requirements: e.target.value })
            }}
            defaultValue={""}
            value={formData.requirements}
            className={errors['repair-required'] ? 'error' : ''}
          >
            <option value="" hidden></option>
            {requirementsOptions.map((option) => (
              <option value={option}>{t(`requirementsOptions.${option}`, lang)}</option>
            ))}
          </select>
        </FormGroup>

        {
          formData.requirements && formData.requirements !== "" && formData.requirements !== 'NOT_NEEDED' && <>
            <FormGroup>
              <label
                
                htmlFor={`requirements${lang}`}>
                {t(`requirementsOptions.exactly`, lang)}
              </label>
              <div className='exactly-required'>
                <div>
                  {t('required', lang)}:
                </div>
                <Field
                  id={`requirements${lang}`}
                  name={`requirements${lang}`}
                  placeholder={t(`form.requirements${lang}`, lang)}
                  className={errors['exactly'] ? 'error' : ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, [`requirements${lang}`]: e.target.value.toLowerCase() })
                    setFieldValue(`requirements${lang}`, e.target.value.toLowerCase())
                  }}
                />
              </div>

            </FormGroup>

            <FormGroup>
              <label htmlFor={`lastMajorRenovation`}>{t(`requirementsOptions.lastMajorRenovation`, lang)}</label>
              <Field
                id={`lastMajorRenovation`}
                name={`lastMajorRenovation`}
                placeholder={t(`requirementsOptions.lastMajorRenovation`, lang)}
                as="input"
                type="date"
                value={formData.lastMajorRenovation ? formData.lastMajorRenovation.split('T')[0] : null}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFormData({ ...formData, lastMajorRenovation: e.target.value })
                  setFieldValue('lastMajorRenovation', e.target.value)
                }}
                className={errors.lastMajorRenovation ? 'error' : ''}
              />
            </FormGroup>
          </>
        }
      </FormBlock>

      <FormBlock>
        <FormGroup>
          <label className='required' htmlFor={`factCondition`}>{t(`form.factCondition`, lang)}</label>
          <select
            value={formData.factCondition}
            onChange={(e) => {
              setFieldValue(`factCondition`, e.target.value);
              setFormData({ ...formData, factCondition: e.target.value })
            }}
            className={errors['factCondition'] ? 'error' : ''}
          >
            <option value="" selected hidden></option>
            {
              options.map((option) => (
                <option key={option.value} value={option.value}>{t(option.label.toLowerCase(), lang)}</option>
              ))
            }
          </select>
        </FormGroup>
      </FormBlock>

      {formData.requirements !== ""
        && formData.requirements !== "NOT_NEEDED"
        && <DocumentationForm
          formData={formData}
          setFormData={setFormData}
          lang={lang}
          setFieldValue={setFieldValue}
          type='gas-supply'
          onAdd={addFund}
          tempFund={tempFund}
          setTempFund={setTempFund}
          onDelete={deleteFund}
          errors={errors}
        />}

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        onSave={handleSave}
        setTempVal={setTempVal}
        errors={errors}
        tempVal={tempVal}
        onDelete={deleteField}
        type='gas-supply'
      />
    </>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  return (
    <Wrapper ref={wrapperRef}>
      <Tabs />
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form >
                <FieldsComponent renderFields={renderFields} setFieldValue={setFieldValue} />
                <FormFooter type='gas-supply' formId={formData.id} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />
      <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'gas-supply'}
        images={images}
        lang={language}
      />
    </Wrapper >
  )
}

export default GasSupplyForm