/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from 'react'
import { PopulationDynamicContainer } from './index.styles'
import { Text } from '../../text.component'
import { DynamicIcon, MenSvg, WomenSvg } from '../../../assets/icons'
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement } from 'chart.js';
import { useTranslation } from 'react-i18next';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ThemeContext, { Theme } from '../../../contexts/theme.context';
import { IPopulationDetails, IPopulationDynamic } from '../../../interfaces/snp.interface';
import { currentYear } from '../../../utils/helpers.utils';
import { BornIcon, DiedIcon, EmigratedIcon, ImmigratedIcon, SaldoIcon } from '../../../assets/icons/population-dynamic';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels, BarElement);

interface IProps {
  populationDynamic: IPopulationDynamic[],
  populationDetails: IPopulationDetails;
}

const icons: any = {
  birthCount: <BornIcon />,
  deathCount: <DiedIcon />,
  immigrationCount: <ImmigratedIcon />,
  emigrationCount: <EmigratedIcon />,
  saldoMigration: <SaldoIcon />,
}


const PupulationDynamicFullScreen: FC<IProps> = ({ populationDynamic, populationDetails }) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const fontCoef = 1;

  const [populationDynamicData, setPopulationDynamicData] = useState<any>(null);
  const [options, setOptions] = useState<any>(null);
  const [barChart, setBarChart] = useState<any>({
    data: null,
    options: null
  });

  const getColor = (year: number) => {
    if (year === currentYear) {
      return theme === Theme.LIGHT ? '#118F84' : '#ffc803';
    } if (year < currentYear) {
      return theme === Theme.LIGHT ? '#98A3A9' : '#fff';
    } else {
      return theme === Theme.LIGHT ? '#606367' : 'rgba(255, 255, 255, 0.5)';
    }
  };

  const getChartInfo = (chartData: IPopulationDynamic[]) => {
    const years = chartData?.map((item: IPopulationDynamic) => item.year);
    const data: any = {
      labels: years,
      datasets: [
        {
          label: 'Население',
          data: chartData.map((item: IPopulationDynamic) => (item.value)),
          borderColor: years.map(year => getColor(year)),
          backgroundColor: years.map(year => getColor(year)),
          pointRadius: 4 * fontCoef,
          pointBackgroundColor: years.map(year => getColor(year)),
          borderDash: years.map(year => year < currentYear ? [] : [5, 5]),
          segment: {
            borderDash: (ctx: any) => +ctx.p0DataIndex >= 3 ? [3, 3] : [],
          },
          spanGaps: true
        },
      ],
    };

    const options: any = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
            drawTicks: false,
          },
          position: 'top',
          ticks: {
            color: years.map(year => getColor(year)),
            align: 'center',
            baseLine: 'middle',
            font: {
              size: years.map(year => year === currentYear ? 14 * fontCoef : 12 * fontCoef),
              weight: years.map(year => year === currentYear ? 700 : 400),
            },
          },
          offset: true,
        },
        y: {
          grid: {
            color: '#c6cacc',
            beginAtZero: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
            maxTicksLimit: 3,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: years.map(year => getColor(year)),
          formatter: function (value: any, context: any) {
            const coef = context.chart.data.datasets[0].data[3] >= 1000 ? 1000 : 1
            return coef === 1 ? value : (value / coef).toFixed(1)?.toLocaleString();
          },
          font: years.map(year => ({
            size: year === currentYear ? 14 * fontCoef : 12 * fontCoef,
            weight: year === currentYear ? 700 : 400,
          })),
          textAlign: 'center',
          clamp: true,
          anchor: 'start',
          align: (context: any) => {
            const index = context.dataIndex;
            const datasets = context.chart.data.datasets[0].data;
            const average = datasets.reduce((acc: number, item: number) => acc + item, 0) / datasets.length;
            return datasets[index] <= average ? 'end' : 'start';
          },
        }
      },
      layout: {
        backgroundColor: '#F8F7EF',
      },
    };

    setOptions(options);

    setPopulationDynamicData(data);
  }

  const getBarChartInfo = (chartData: IPopulationDetails) => {
    const barChartData: any = {
      // labels: ['youthPopulationCount', 'employedPopulationCount', 'hiredPopulationCount', 'selfEmployedPopulationCount'].map(item => t('populationDetailsStats.' + item).toUpperCase()),
      labels: ['employedPopulationCount', 'hiredPopulationCount', 'selfEmployedPopulationCount'].map(item => t('populationDetailsStats.' + item).toUpperCase()),
      datasets: [
        {
          label: 'Человек',
          // data: [chartData.youthPopulationCount, chartData.employedPopulationCount, chartData.hiredPopulationCount, chartData.selfEmployedPopulationCount],
          data: [chartData.employedPopulationCount, chartData.hiredPopulationCount, chartData.selfEmployedPopulationCount],
          backgroundColor: theme === Theme.DARK ? "#ffc803" : '#118F84',
          borderColor: theme === Theme.DARK ? "#ffc803" : '#118F84',
        },
      ],
    };

    const maxSum = Math.max(...barChartData.datasets[0].data);

    const barChartOptions: any = {
      indexAxis: 'y',
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyle: 'rect',
          },
          display: false,
        },
        datalabels: {
          display: true,
          anchor: 'end',
          align: 'end',
          color: theme === Theme.DARK ? "#fff" : '#000',
          font: {
            size: 16,
            weight: 'bold'
          },
          formatter: function (value: any, context: any) {
            return value?.toLocaleString();
          }
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          display: false,
          grid: {
            display: false
          },
          stacked: true,
          min: 0,
          max: maxSum * 1.25,
        },
        y: {
          stacked: true,
          grid: {
            display: false
          },
          ticks: {
            color: theme === Theme.LIGHT ? '#202224' : '#fff',
            font: {
              size: 16,
            }
          }
        }

      },
      responsive: true,
      maintainAspectRatio: false
    }

    setBarChart({
      data: barChartData,
      options: barChartOptions
    });
  }

  useEffect(() => {
    if (populationDynamic) {
      getChartInfo(populationDynamic);
    }
  }, [populationDynamic, theme])

  useEffect(() => {
    getBarChartInfo(populationDetails);
  }, [populationDetails, theme])

  return (
    populationDynamicData && populationDetails && <PopulationDynamicContainer>
      <div className='dates'>
        <div></div>
        <div>
          <Text fontSize="0.75rem" color="var(--shuttleGray)" fontWeight={500}>январь-авг 2024</Text>
          <Text fontSize="0.75rem" color="var(--shuttleGray)" fontWeight={500}>чел.</Text>
        </div>
      </div>

      <div className="population">
        <div className="left">
          <div className="upper">
            <div className="total">
              <Text fontSize='3.625rem' color='#fff' fontWeight={700}>{populationDetails.populationCount?.toLocaleString() || 0}</Text>
              <Text fontSize='1.5rem' color='#fff' fontWeight={700}>чел.</Text>
            </div>

            <div className="genders">
              <div className='gender'>
                <div className="icon">
                  <MenSvg />
                </div>
                <div className='info'>
                  <Text fontSize='1.875rem' color='#fff' fontWeight={700}>{populationDetails.maleCount?.toLocaleString() || 0}</Text>
                  <Text fontSize='0.75rem' color='#fff' fontWeight={700}>{t('populationDetailsStats.maleCount')}</Text>
                </div>
              </div>
              <div className='gender'>
                <div className="icon">
                  <WomenSvg />
                </div>
                <div className='info'>
                  <Text fontSize='1.875rem' color='#fff' fontWeight={700}>{populationDetails.femaleCount?.toLocaleString() || 0}</Text>
                  <Text fontSize='0.75rem' color='#fff' fontWeight={700}>{t('populationDetailsStats.femaleCount')}</Text>
                </div>
              </div>
            </div>
          </div>

          <div className="lower">
            <div className="title">
              <div className="title__left">
                <DynamicIcon />
                <Text fontSize='0.875rem' color='var(--shuttleGray)' fontWeight={700}>{t('populationDetailsStats.populationCount')}</Text>
              </div>
              <div className="title__right">
                <Text fontSize='0.9375rem' color='var(--text-color)' fontWeight={400}>{populationDetails.populationCount?.toLocaleString() || 0}</Text>
              </div>
            </div>

            <div className="line-chart-container">
              {<Line data={populationDynamicData} options={options} plugins={[ChartDataLabels]} />}
            </div>
          </div>
        </div>

        <div className="right">
          {['birthCount', 'deathCount', 'immigrationCount', 'emigrationCount', 'saldoMigration'].map((item) => (
            <div className="population__item">
              <div className="population__item__left">
                <div className="icon">{icons[item]}</div>
                <Text fontSize='0.875rem' color='var(--shuttleGray)' fontWeight={700}>{t('populationDetailsStats.' + item)}</Text>
              </div>
              <div className="population__item__right">
                <Text fontSize='0.9375rem' color='var(--text-color)' fontWeight={400}>{populationDetails[item as keyof IPopulationDetails]?.toLocaleString() || 0}</Text>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='dates' style={{ margin: 0 }}>
        <div></div>
        <div>
          <div></div>
          <Text fontSize="0.75rem" color="var(--shuttleGray)" fontWeight={500}>чел. </Text>
        </div>
      </div>
      <div className="employment">
        <div className="employment__left">
          <Text fontSize="1.5rem" color="var(--text-color)" fontWeight={700}>{t('populationDetailsStats.economicallyActivePopulation')}</Text>
          <div className='employment__value'>
            <Text fontSize="2.25rem" color="var(--primary-color)" fontWeight={700}>{populationDetails.populationEmploymentCount?.toLocaleString() || 0}</Text>
            <Text fontSize="1.25rem" color="var(--primary-color)" fontWeight={700}>чел.</Text>
          </div>

          <div className='employment__percent'>
            <Text fontSize="2rem" color="#F07B1A" fontWeight={700}>{populationDetails.economicallyActivePopulation?.toLocaleString() || 0}%</Text>
          </div>

        </div>
        {barChart.data && barChart.options && <div className="employment__right">
          <div className='chart-container' >
            <Bar data={barChart.data} options={barChart.options} />
          </div>
        </div>}
      </div>
    </PopulationDynamicContainer>
  )
}

export default PupulationDynamicFullScreen